import React from 'react';
import { navigate } from 'gatsby';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchPageInput, SearchWrapper, SearchIcon, Form, Input } from './styles';

export default connectSearchBox(
  ({ searchPage, queryParams, currentRefinement, refine, ...rest }) => (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (!searchPage) {
          navigate(`/search?=${currentRefinement}`);
        }
      }}
    >
      {!searchPage ? (
        <>
          <Input
            defaultValue={queryParams ? Object.values(queryParams) : null}
            type="text"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => refine(e.target.value)}
            {...rest}
          />
          <SearchIcon />
        </>
      ) : (
        <SearchWrapper>
          <SearchIcon />
          <SearchPageInput
            defaultValue={queryParams ? Object.values(queryParams) : null}
            type="text"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => refine(e.target.value)}
            {...rest}
          />
        </SearchWrapper>
      )}
    </Form>
  ),
);
