import React from 'react';
import { Box, Heading, ResponsiveContext, ThemeContext } from 'grommet';
import styled from 'styled-components';
import { FullsizePicture } from '../Picture';
import GatsbyAnchor from '../GatsbyAnchor';

const StyledHeading = styled(Heading)`
  letter-spacing: 1.5px;
  line-height: 22px;
  font-weight: bold;
  width: 100%;
`;

const MarketLabel = styled(Box)`
  min-height: 72px;
`;

const PictureBox = styled(Box)`
  display: none;

  @media screen and (min-width: 769px) {
    display: block;
  }
`;

const theme = {
  anchor: {
    textDecoration: 'none',
    hover: {
      textDecoration: 'none',
    },
  },
};

function setMarket(market) {
  if (typeof window !== 'undefined' && market) localStorage.setItem('selectedMarket', market);
}

export default function({ Link, market, selected }) {
  const { name, _meta, thumbnail } = market;
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const width = size === 'small' ? '100%' : '20%';
        const border =
          size === 'small'
            ? {
                color: 'neutral-4',
                side: 'all',
              }
            : {
                color: 'dark-1',
                side: 'all',
              };
        let bgSelected;
        if (size === 'small') {
          bgSelected = selected ? 'white' : 'neutral-4';
        } else {
          bgSelected = selected ? 'white' : 'dark-1';
        }
        const margin = size === 'small' ? 'none' : 'small';
        const align = size === 'small' ? 'start' : 'center';
        const padding = size === 'small' ? { horizontal: '36px', vertical: 'small' } : 'small';
        const headingSize = size === 'small' ? '18px' : '14px';

        return (
          <Box width={width} direction="column">
            <ThemeContext.Extend value={theme}>
              <GatsbyAnchor
                Link={Link}
                href={`/${_meta.uid}/`}
                onClick={() => {
                  setMarket(_meta.uid);
                  window.dataLayer.push({
                    event: 'GAEvent',
                    eventCategory: 'Homepage Navigation',
                    eventAction: 'Top Nav',
                    eventLabel: name,
                  });
                }}
                margin={margin}
              >
                <PictureBox height="110px">
                  <FullsizePicture src={thumbnail.url} alt={thumbnail.alt || ''} />
                </PictureBox>
                <MarketLabel
                  align={align}
                  background={bgSelected}
                  border={border}
                  justify="center"
                  pad={padding}
                >
                  <StyledHeading
                    size={headingSize}
                    level="5"
                    margin="none"
                    textAlign={align}
                    uppercase
                  >
                    {name}
                  </StyledHeading>
                </MarketLabel>
              </GatsbyAnchor>
            </ThemeContext.Extend>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
