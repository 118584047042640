/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const HubspotPost = ({ portalId, formId }) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const [data, setData] = useState();
  const [form, setForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorData, setErrorData] = useState();

  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);

    const fields = Object.keys(form).map((key) => ({ name: key, value: form[key] }));

    // Handle exception where Hubspot API expects a string of value;value instead of ['value', 'value']
    fields.forEach((field, index) => {
      if (field.name === 'sup_div_ucp_type') {
        let { value } = fields[index];
        value = value.join(';');
        fields[index].value = value;
      }
    });

    try {
      const result = await fetch(url, {
        method: 'post',
        body: JSON.stringify({ fields }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const json = await result.json();

      if (json.errors && json.errors.length) {
        setIsError(true);
        setSubmitted(false);
        setErrorData(json.errors.map(({ message }) => message).join('\n'));
      } else {
        setData(json);
        setErrorData();
        setSubmitted(true);
      }

      setForm(false);
      setIsLoading(false);
    } catch (err) {
      setIsError(true);
      setForm(false);
      setSubmitted(false);
      setErrorData(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (form) {
      fetchData();
    }
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setForm(e.value);
  };

  return { data, isLoading, isError, handleSubmit, errorData, submitted };
};

export default HubspotPost;
