import React from 'react';
import { Box, Heading, Text, Paragraph } from 'grommet';
import { format, addDays } from 'date-fns';
import styled from 'styled-components';
import GatsbyAnchor from '../GatsbyAnchor';

const EventDateHolder = styled(Heading)`
  font-size: ${(props) => (props.size === 'small' ? '30px' : '63px')};
  letter-spacing: ${(props) => (props.size === 'small' ? '-0.68px' : '-1.5px')};
  line-height: ${(props) => (props.size === 'small' ? '25px' : '59px')};
`;

const StyledLocation = styled.i`
  font-family: Roboto, sans-serif;
`;

const StyledAnchor = styled(GatsbyAnchor)`
  font-size: 15px;
  color: #fff;
`;

export default function({
  Link,
  size,
  eventDate,
  eventTitle,
  eventDescription,
  eventLocation,
  eventUrl,
}) {
  return (
    <Box>
      {eventDate && (
        <EventDateHolder level="2" margin="none" size={size}>
          {format(addDays(new Date(eventDate), 1), 'MMM dd')}
        </EventDateHolder>
      )}
      {eventTitle && (
        <Heading level="6" margin={{ top: 'small', bottom: 'none' }} size={size}>
          {eventTitle}
        </Heading>
      )}
      <Text size={size} margin="none">
        {eventLocation && <StyledLocation>{eventLocation}</StyledLocation>}
        {eventDescription && <Paragraph>{eventDescription}</Paragraph>}
        {eventUrl && <StyledAnchor Link={Link} label="Learn more" href={eventUrl} />}
      </Text>
    </Box>
  );
}
