import states from './states.json';

export function stateAbbr(state) {
  return states.reduce((abbr, { label, value }) => (label === state ? value : abbr), null);
}

export function regionByState(state) {
  switch (state) {
    case 'Alaska':
    case 'California':
    case 'Hawaii':
    case 'Oregon':
    case 'Washington':
      return 'Pacific';
    case 'Colorado':
    case 'Idaho':
    case 'Montana':
    case 'Nevada':
    case 'Utah':
    case 'Wyoming':
      return 'Rocky Mountain';
    case 'Iowa':
    case 'Illinois':
    case 'Indiana':
    case 'Kansas':
    case 'Michigan':
    case 'Minnesota':
    case 'Missouri':
    case 'North Dakota':
    case 'Nebraska':
    case 'Ohio':
    case 'South Dakota':
    case 'Wisconsin':
      return 'Midwest';
    case 'Arizona':
    case 'New Mexico':
    case 'Oklahoma':
    case 'Texas':
      return 'Southwest';
    case 'Alabama':
    case 'Arkansas':
    case 'Florida':
    case 'Georgia':
    case 'Kentucky':
    case 'Louisiana':
    case 'Mississippi':
    case 'North Carolina':
    case 'South Carolina':
    case 'Tennessee':
    case 'Virginia':
    case 'West Virginia':
      return 'Southeast';
    case 'Connecticut':
    case 'D.C.':
    case 'Delaware':
    case 'Massachusetts':
    case 'Maryland':
    case 'Maine':
    case 'New Hampshire':
    case 'New Jersey':
    case 'New York':
    case 'Pennsylvania':
    case 'Rhode Island':
    case 'Vermont':
      return 'Northeast';
    default:
      return 'International';
  }
}
