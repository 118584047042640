import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Cta, Picture } from '../../index';
import { mainPadding, mainGap } from '../../utils/layout';

export default function ServiceBlock({ size, image, ...rest }) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        return (
          <Box justify="center" pad={mainPadding(sizeResponsive)}>
            <Box
              gap={mainGap(sizeResponsive)}
              direction="row-responsive"
              width="1440px"
              margin="auto"
            >
              <Box basis="49%">
                <Picture src={image.url} alt={image.alt || ''} width="100%" />
              </Box>
              <Box basis="49%" alignSelf="center">
                <Cta {...rest} />
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
