import React from 'react';
import { Box, Heading, Button, Image, ThemeContext, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import EyebrowBox from '../../typography/EyebrowBox';
import BodyText from '../../typography/BodyText';

export default function AuthorCard({ authorProps, htmlSerializer }) {
  const { name, position, blurb, image, contactText, contactUrl } = authorProps;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';
        const isMedium = ['medium', 'small'].includes(size);

        return (
          <Box
            direction={isMedium ? 'column' : 'row'}
            background="accent-2"
            margin={!isSmall && { bottom: 'large' }}
          >
            <Box
              align="center"
              pad={{ horizontal: 'large' }}
              margin={isSmall && { top: 'large' }}
              flex="grow"
            >
              {isMedium && (
                <EyebrowBox margin={{ horizontal: 'auto', bottom: 'small', top: 'large' }} dark>
                  author
                </EyebrowBox>
              )}
              <Box
                flex={false}
                height="150px"
                margin={{ vertical: isMedium ? 'medium' : 'large' }}
                overflow="hidden"
                round="full"
                width="150px"
              >
                <Image
                  height="100%"
                  width="100%"
                  fit="cover"
                  src={image.url}
                  alt={image.alt || ''}
                />
              </Box>
              {!isMedium && <Button label={contactText} href={contactUrl} />}
            </Box>
            <Box
              fill="horizontal"
              pad={
                isMedium
                  ? { horizontal: 'large', bottom: 'large' }
                  : { vertical: 'large', right: 'large' }
              }
            >
              {!isMedium && <EyebrowBox dark>author</EyebrowBox>}
              <ThemeContext.Extend
                value={{ heading: { weight: 700, level: { 4: { medium: { size: '26px' } } } } }}
              >
                <Heading
                  level={4}
                  margin={{ vertical: 'xsmall' }}
                  textAlign={isMedium && 'center'}
                  uppercase
                >
                  {name}
                </Heading>
                <Heading
                  margin={isMedium ? { bottom: 'medium', top: 'none' } : 'none'}
                  level={6}
                  textAlign={isMedium && 'center'}
                  size={size}
                >
                  {position}
                </Heading>
              </ThemeContext.Extend>
              <BodyText color="light-1" textAlign={isMedium && 'center'}>
                {Array.isArray(blurb) ? (
                  <RichText render={blurb} htmlSerializer={htmlSerializer} />
                ) : (
                  blurb
                )}
              </BodyText>
              {isMedium && contactUrl && (
                <Button
                  alignSelf="center"
                  margin={{ vertical: 'small' }}
                  label={contactText}
                  href={contactUrl}
                />
              )}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
