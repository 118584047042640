import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';

import { Next, Previous } from 'grommet-icons';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';

import HotJobsCard from '../HotJobsCard';

const StyledButtonBack = styled(ButtonBack)`
  background-color: #fff;
  color: white;
  border: none;
`;

const StyledButtonNext = styled(ButtonNext)`
  background-color: #fff;
  color: white;
  border: none;
`;

const StyledDots = styled(DotGroup)`
  button {
    height: 10px;
    width: 10px;
    margin-right: 10px;
    border-color: #272727;
    padding: 0px;
  }
  .carousel__dot--selected {
    background-color: #272727;
  }
`;

export default function({ size, allJobs, htmlSerializer }) {
  const allMappedJobs = allJobs.map((elem, index) => {
    return (
      <Slide index={index}>
        <HotJobsCard
          size={size}
          jobTitle={elem.jobTitle}
          jobLocation={elem.location}
          jobDescription={elem.description}
          jobUrl={elem.url}
          htmlSerializer={htmlSerializer}
        />
      </Slide>
    );
  });

  return (
    <Box>
      <CarouselProvider
        naturalSlideWidth={1}
        naturalSlideHeight={1}
        totalSlides={3}
        visibleSlides={1}
        lockOnWindowScroll
      >
        <Box direction="row" justify="between">
          <Slider style={{ height: '100%', width: '100%' }}>{allMappedJobs}</Slider>
        </Box>
        <Box direction="row" justify="between">
          <StyledButtonBack>
            <Previous />
          </StyledButtonBack>

          <StyledDots disableActiveDots={false} />

          <StyledButtonNext>
            <Next />
          </StyledButtonNext>
        </Box>
      </CarouselProvider>
    </Box>
  );
}
