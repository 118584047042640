import styled from 'styled-components';
import { Text } from 'grommet';

export default styled(Text).attrs(() => ({
  color: 'accent-3',
}))`
  font-weight: 300;
  font-family: Roboto, sans-serif;
  font-size: 63px;
  letter-spacing: -1.5px;
  line-height: 59px;

  @media only screen and (max-width: 1280px) {
    font-size: 30px;
    letter-spacing: -0.68px;
    line-height: 25px;
  }
`;
