import styled from 'styled-components';
import { Heading } from 'grommet';

export default styled(Heading).attrs(({ margin }) => ({
  level: 5,
  margin: margin || { top: 'none', bottom: 'xsmall' },
}))`
  align-self: start;
  border: 1px solid ${({ dark }) => (dark ? '#fff' : '#333')};
  color: ${({ dark }) => (dark ? '#fff' : '#333')};
  display: inline-block;
  box-sizing: content-box;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 1;
  padding: 6px 12px;
  text-decoration: none;
  text-transform: uppercase;

  @media only screen and (max-width: 768px) {
    font-size: 11px;
  }
`;
