import React from 'react';
import { Box, Button, Form, FormField, Heading, Paragraph } from 'grommet';
import styled from 'styled-components';
import HubspotPost from '../HubspotPost';
import GatsbyButton from '../GatsbyButton';

const StyledForm = styled(Form)`
  width: 100%;
  input {
    background: white;
    color: #272727;
  }
`;

const StyledParagraph = styled(Paragraph)`
  max-width: 100%;
`;

const StyledP = styled.p`
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.08px;
  line-height: 22px;
  color: #d9d9d6;
`;

const StyledFormField = styled(FormField)`
  label {
    margin-left: 0px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
  div:first-child,
  textarea {
    border: 1px solid #272727;
  }
  div:first-child {
    div {
      border: none;
    }
  }
`;

const HiddenFormField = styled(FormField)`
  display: none;
`;

export default function SignupDownload({ buttonLabel, href, size, location, ctaHref, ctaLabel }) {
  const { isError, handleSubmit } = HubspotPost({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_SIGN_UP_ID,
  });

  // Create cookie
  function setCookie(cname, cvalue, exdays) {
    const date = new Date();
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  }
  // Read cookie name
  function getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }
  // Returns true when the cookie has been set.
  const submitCookie = getCookie('submittedCookie');

  async function asyncHandleSubmit(e) {
    await handleSubmit(e);
    setCookie('submittedCookie', true, 60);
  }

  const fieldWidth = size === 'small' ? '100%' : '50%';

  return (
    <Box
      width="1440px"
      margin="auto"
      background="dark-1"
      border={{ color: 'dark-1', size: '1px' }}
      pad="medium"
    >
      {submitCookie ? (
        <>
          <Box>
            <Heading level={3} margin="none">
              Download White Paper
            </Heading>
            <StyledParagraph>Thank you! Your White Paper is ready.</StyledParagraph>
            <div>
              {!ctaHref && !ctaLabel ? <GatsbyButton href={href} label={buttonLabel} /> : null}
            </div>
          </Box>
        </>
      ) : (
        <>
          <Box pad={{ horizontal: 'small' }}>
            <Heading level={3} margin="none">
              Download Full Paper
            </Heading>
            <StyledParagraph>Fill out the form to get this paper now!</StyledParagraph>
          </Box>
          <StyledForm onSubmit={asyncHandleSubmit}>
            <Box direction="row-responsive" wrap pad={{ vertical: 'small', horizontal: 'small' }}>
              <StyledP>* Required Fields</StyledP>
            </Box>
            <Box direction="row-responsive" wrap>
              <Box width={fieldWidth} pad={{ horizontal: 'small' }}>
                <StyledFormField label="First Name *" name="firstname" required />
              </Box>
              <Box width={fieldWidth} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Last Name *" name="lastname" required />
              </Box>
            </Box>
            <Box direction="row-responsive" wrap>
              <Box width={fieldWidth} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Company" name="company" />
              </Box>
              <Box width={fieldWidth} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Role" name="role" />
              </Box>
            </Box>
            <Box direction="row-responsive" wrap>
              <Box width="100%" pad={{ horizontal: 'small' }}>
                <StyledFormField label="Email *" name="email" required />
              </Box>
            </Box>
            <HiddenFormField name="source" value={location.href} />
            <Box align="center">
              <Button type="submit" label="Submit" primary />
              {isError ? 'There was an error submitting the form' : ''}
            </Box>
          </StyledForm>
        </>
      )}
    </Box>
  );
}
