import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import GatsbyAnchor from '../../components/GatsbyAnchor';
import MarketSelector from '../../components/MarketSelector';
import Search from '../../components/Search';

export const searchIndices = [
  { name: `Publications`, title: `Publications`, hitComp: `GeneralHit` },
  { name: `ServiceAreas`, title: `Services`, hitComp: `GeneralHit` },
  { name: `Industries`, title: `Industries`, hitComp: `GeneralHit` },
  { name: `Pages`, title: `Other Pages`, hitComp: `GeneralHit` },
];

const ClientLogin = styled(GatsbyAnchor)`
  font-family: Roboto, sans-serif;
  text-decoration: none;
  transition: 0.1s;
  &:hover {
    color: #1082a4;
  }
`;

const StyledGatsbyAnchor = styled(GatsbyAnchor)`
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  transition: 0.1s;
  letter-spacing: 1px;
  position: relative;
  align-self: stretch;
  justify-content: center;

  &:hover {
    color: #1082a4;
  }

  &:after {
    display: none;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6px;
    width: 45px;
    background-color: #000;
  }

  &[aria-current='page'] {
    color: #1082a4;

    &:after {
      @media screen and (min-width: 1024px) {
        display: block;
      }
    }
  }
`;

const HRule = styled.hr`
  background-color: #979797;
  border: 0;
  height: 1px;
  width: 75%;
`;

const VRule = styled.div`
  background-color: #000;
  display: block;
  height: 44px;
  width: 1px;
`;

export default function({ collapse, color, Link, headerRef, markets, mobile, ...rest }) {
  const marketSelectorProps = !headerRef
    ? null
    : {
        dropTarget: headerRef.current,
        dropAlign: { top: 'bottom' },
      };

  return (
    <Box as="nav" {...rest}>
      <MarketSelector Link={Link} markets={markets} mobile={mobile} {...marketSelectorProps} />
      <StyledGatsbyAnchor color={color} Link={Link} href="/services">
        Services
      </StyledGatsbyAnchor>
      <StyledGatsbyAnchor color={color} Link={Link} href="/about">
        About
      </StyledGatsbyAnchor>
      <StyledGatsbyAnchor color={color} Link={Link} href="/careers">
        Careers
      </StyledGatsbyAnchor>
      {!mobile ? <VRule /> : <HRule />}
      <ClientLogin color={color} href="https://pwc.powereng.com/PWC">
        Client Login
      </ClientLogin>
      {!mobile && <VRule />}
      <Search collapse={collapse} mobile={mobile} color={color} indices={searchIndices} />
    </Box>
  );
}
