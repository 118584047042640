import React from 'react';
import { Box, Heading, Paragraph, Text } from 'grommet';
import styled from 'styled-components';
import { stateAbbr } from './utils';

const StateText = styled(Text)`
  height: 23px;
  color: #cb361d;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 22px;
  text-transform: uppercase;
`;

const DistanceText = styled(Paragraph)`
  color: #4b4b4b;
  font-family: Roboto, sans-serif;
  font-style: italic;
`;

export default function LocationCard({ location, size, ...rest }) {
  return (
    <Box margin={{ top: 'large' }} pad={{ right: 'medium' }} flex={false} {...rest}>
      <StateText>{location.state || location.country}</StateText>
      <Heading level="4" color="dark-4" margin="none" size={size}>
        {location.name}
      </Heading>
      <Paragraph margin="none" size={size}>
        {location.address1}
        {location.address2 && (
          <>
            <br />
            {location.address2}
          </>
        )}
        <br />
        {location.city}, {stateAbbr(location.state)} {location.zip_code}
        <br />
        {location.country}
        <br />
        Phone: {location.phone1}
        {location.phone2 && (
          <>
            <br />
            Phone: {location.phone2}
          </>
        )}
        {location.fax && (
          <>
            <br />
            Fax: {location.fax}
          </>
        )}
      </Paragraph>
      {location.dist > 0 && (
        <DistanceText margin={{ top: 'medium', bottom: 'none' }} size={size}>
          {location.dist} miles away
        </DistanceText>
      )}
    </Box>
  );
}
