import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { Cta } from '../../index';

const CenteredText = styled.div`
  text-align: center;
  width: 100%;

  div {
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 1280px) {
    p {
      max-width: 66%;
    }
  }
`;

export default function AboutBlock({ ...rest }) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const padMobile =
          sizeResponsive === 'small'
            ? { horizontal: 'medium', vertical: '100px' }
            : { horizontal: 'xlarge', vertical: 'xlarge' };
        return (
          <Box align="center" background="radial-gradient(circle, #DE3F25 0%, #982400 100%)">
            <Box direction="row" pad={padMobile} width="1440px" justify="center">
              <CenteredText>
                <Cta {...rest} />
              </CenteredText>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
