import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import { Next, Previous } from 'grommet-icons';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import EventsCard from '../EventsCard';

const StyledButtonBack = styled(ButtonBack)`
  background-color: #cb361d;
  color: white;
  border: none;
`;

const StyledButtonNext = styled(ButtonNext)`
  background-color: #cb361d;
  color: white;
  border: none;
`;

const StyledDots = styled(DotGroup)`
  text-align: center;

  button {
    background: none;
    border: 1px solid #fff;
    height: 10px;
    margin: 0 5px;
    padding: 0;
    width: 10px;

    &.carousel__dot--selected {
      background-color: #fff;
    }
  }
`;

export default function({ size, allEvents }) {
  const allMappedEvents = allEvents.map((elem, index) => {
    return (
      <Slide index={index} style={{ height: '10px' }}>
        <EventsCard
          size={size}
          eventDate={elem.eventDate}
          eventTitle={elem.eventTitle}
          eventDescription={elem.eventDescription}
          eventLocation={elem.eventLocation}
          eventUrl={elem.eventUrl}
        />
      </Slide>
    );
  });

  return (
    <Box>
      <CarouselProvider
        naturalSlideWidth={1}
        naturalSlideHeight={1.3}
        totalSlides={3}
        visibleSlides={1}
        lockOnWindowScroll
      >
        <Box direction="row" justify="between">
          <Slider style={{ height: '100%', width: '100%' }}>{allMappedEvents}</Slider>
        </Box>
        <Box direction="row" justify="between">
          <StyledButtonBack>
            <Previous />
          </StyledButtonBack>

          <StyledDots disableActiveDots={false} />

          <StyledButtonNext>
            <Next />
          </StyledButtonNext>
        </Box>
      </CarouselProvider>
    </Box>
  );
}
