import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Anchor, Box } from 'grommet';
import scrollToElement from 'scroll-to-element';

const SecondaryNavOuterContainer = styled(Box)`
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const SecondaryNavContainer = styled(Box)`
  max-width: 1440px;
  margin: 0 auto;
`;

const StyledAnchor = styled(Anchor)`
  align-items: center;
  align-self: stretch;
  color: #272727;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding: 1rem 2% 1rem 0.5rem;
  position: relative;
  text-decoration: none;
  transition: 0.1s;

  &.active,
  &:hover,
  &:focus {
    color: #0d7ea0;
  }

  &.active:after {
    background-color: #272727;
    bottom: 0;
    content: '';
    display: block;
    height: 6px;
    position: absolute;
    width: 40px;
  }
`;

export default function({ links }) {
  if (!links) return null;

  const ref = useRef(null);
  const [activeNav, setActiveNav] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  function adjustHeight() {
    const header = document.querySelector('#header');
    setHeaderHeight(header.clientHeight);
  }

  function sanitizeId(Id) {
    return Id.replace(/[/,]/g, '');
  }

  useEffect(() => {
    adjustHeight();
    window.addEventListener('resize', adjustHeight, { passive: true });

    return function cleanup() {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  return (
    links && (
      <SecondaryNavOuterContainer
        background="white"
        border="bottom"
        pad={{ horizontal: 'xlarge' }}
        ref={ref}
        style={headerHeight ? { top: `${headerHeight}px` } : null}
      >
        <SecondaryNavContainer fill="horizontal" direction="row" justify="start">
          {links.map(({ id, title, link }, index) => (
            <StyledAnchor
              key={id}
              className={index === activeNav ? 'active' : null}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveNav(index);
                scrollToElement(sanitizeId(link), {
                  offset: -headerHeight - ref.current.clientHeight,
                  ease: 'inOutQuad',
                  duration: 600,
                });
              }}
              href={sanitizeId(link)}
            >
              {title}
            </StyledAnchor>
          ))}
        </SecondaryNavContainer>
      </SecondaryNavOuterContainer>
    )
  );
}
