import React from 'react';
import { Box, Heading, ResponsiveContext } from 'grommet';
import HowWeWorkCard from '../../components/HowWeWorkCard';
import { mainPadding } from '../../utils/layout';

export default function HowWeWork({ heading, cardData, htmlSerializer }) {
  const HowWeWorkCards = cardData.map((data) => (
    <HowWeWorkCard key={data.id} cardData={data} htmlSerializer={htmlSerializer} />
  ));

  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        return (
          <Box pad={mainPadding(sizeResponsive)}>
            <Box width="1440px" alignSelf="center">
              <Heading size={sizeResponsive} textAlign="center" level="2">
                {heading}
              </Heading>
            </Box>
            {HowWeWorkCards}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
