import React from 'react';
import { Box } from 'grommet';
import { FacebookOption, X, Mail, LinkedinOption } from 'grommet-icons';
import ShareLinksButton from './ShareLinksButton';

const socialData = [
  {
    name: 'Facebook',
    icon: <FacebookOption color="white" />,
    background: '#43619C',
  },
  {
    name: 'X',
    icon: <X color="white" />,
    background: 'black',
  },
  {
    name: 'Linkedin',
    icon: <LinkedinOption color="white" />,
    background: '#0077B5',
  },
  {
    name: 'Mail',
    icon: <Mail />,
    background: '#2D2D2D',
  },
];

export default function({ title }) {
  return (
    <Box direction="row" justify="start">
      {socialData.map((card) => (
        <ShareLinksButton {...card} title={title} />
      ))}
    </Box>
  );
}
