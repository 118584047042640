/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  background-color: #272727;
  padding: 20px;

  iframe {
    width: 100% !important;

    .hs-button {
      background-color: transparent !important;
    }
  }
`;

export default function({ hubspotFormId }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const jqueryScript = document.createElement('script');
    jqueryScript.src = 'https://code.jquery.com/jquery-3.4.1.min.js';
    document.body.appendChild(jqueryScript);

    const hubSpotScript = document.createElement('script');
    hubSpotScript.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(hubSpotScript);

    hubSpotScript.addEventListener('load', () => {
      if (window.hbspt && !loaded) {
        setLoaded(true);

        window.hbspt.forms.create({
          portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
          formId: hubspotFormId || process.env.GATSBY_SIGN_UP_ID,
          target: '#hubspotForm',
          onFormReady: ($form) => {
            $form.parents('body').css({
              color: '#fff',
              fontFamily: 'Roboto Slab, sans-serif',
              fontSize: '1.125rem',
            });

            // Override the button styles
            // eslint-disable-next-line no-undef
            $form.find('input[type="submit"]').css({
              color: 'white',
              'background-color': 'transparent',
              'font-weight': '500',
              'text-transform': 'uppercase',
              'font-size': '16px',
            });
          },
        });
      }
    });

    return () => {
      document.body.removeChild(jqueryScript);
      document.body.removeChild(hubSpotScript);
    };
  }, []);

  return (
    <>
      <StyledDiv id="hubspotForm" />
    </>
  );
}
