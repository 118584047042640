import React from 'react';
import { Box, Heading, Paragraph, Image } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import successImage from './success.svg';

export default function SupplierSuccess({
  successBody,
  successButtonLabel,
  successButtonTo,
  successHeading,
}) {
  return (
    <Box pad={{ vertical: '60px', horizontal: 'xlarge' }} align="center">
      <Box justify="center">
        <Image src={successImage} alt="success" />
      </Box>
      <Heading size="1">{successHeading}</Heading>
      <Paragraph textAlign="center">{successBody}</Paragraph>
      <GatsbyButton label={successButtonLabel} href={successButtonTo} />
    </Box>
  );
}
