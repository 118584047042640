import React from 'react';
import { Box, Heading, Paragraph, ResponsiveContext, ThemeContext } from 'grommet';
import styled from 'styled-components';
import GatsbyButton from '../../components/GatsbyButton';
import EyebrowBox from '../../typography/EyebrowBox';
import GatsbyAnchor from '../../components/GatsbyAnchor';

const ButtonContainer = styled.div`
  padding-top: 10px;
`;

const StyledBox = styled(Box)`
  background-color: #c4ebf6;
`;

const StyledParagraph = styled(Paragraph)`
  max-width: 100%;
`;

export default function CurrentsCard({
  Link,
  currentsHeading,
  currentsBody,
  tagHref,
  tagLabel,
  type,
  contentType,
  ...rest
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';

        return (
          <Box
            alignSelf="stretch"
            pad={isSmall ? { vertical: 'medium', bottom: 'xlarge' } : 'small'}
            width={isSmall ? '100%' : '33.333%'}
          >
            <StyledBox pad={isSmall ? 'large' : 'medium'} height="100%">
              {type && (
                <EyebrowBox Link={Link} as={GatsbyAnchor} href={`/library?type=${type}`}>
                  {type}
                </EyebrowBox>
              )}
              <Heading size={size} level="2" margin={{ vertical: 'medium' }}>
                {currentsHeading}
              </Heading>
              <StyledParagraph margin={{ top: 'none' }}>{currentsBody}</StyledParagraph>
              <ButtonContainer>
                <ThemeContext.Extend
                  value={{ button: { padding: { vertical: '10px', horizontal: '25px' } } }}
                >
                  <GatsbyButton
                    Link={Link}
                    color="dark-1"
                    primary
                    label={tagLabel}
                    href={tagHref || null}
                    {...rest}
                  />
                </ThemeContext.Extend>
              </ButtonContainer>
            </StyledBox>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
