import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql';
import { createGlobalStyle } from 'styled-components';
import { Footer, Grommet, Header, powerTheme } from '../ui';
import SEO from '../components/seo';
import htmlSerializer from '../prismic/htmlSerializer';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
  .subscript {
    font-size: 80%;
    vertical-align: sub;
  }
  .superscript {
    font-size: 80%;
    vertical-align: super;
  }
`;

const contentQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    prismic {
      allMarkets(sortBy: priority_ASC) {
        edges {
          node {
            name
            thumbnail
            _meta {
              id
              uid
            }
          }
        }
      }
      allFooters {
        edges {
          node {
            contact_title
            find_location
            phone_number
            fax_number
            registration_title
            registration_text
            certification_logo
            certification_text
            links_title
            instagram_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            linkedin_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            twitter_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            youtube_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            glassdoor_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            links {
              link_text
              link_url {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'medium', vertical: 'xlarge' };
    case 'medium':
      return 'large';
    default:
      return 'xlarge';
  }
}

export default function({ title, description, image, children }) {
  return (
    <StaticQuery
      query={contentQuery}
      render={withPreview((data) => {
        return (
          <>
            <SEO data={data} title={title} description={description} image={image} />
            <GlobalStyle />
            {typeof window !== 'undefined' && (
              <Grommet theme={powerTheme} userAgent={window.navigator.userAgent}>
                <Header Link={Link} markets={data.prismic.allMarkets.edges} />
                <main>{children}</main>
                <Footer
                  Link={Link}
                  content={data.prismic.allFooters.edges[0].node}
                  htmlSerializer={htmlSerializer}
                />
              </Grommet>
            )}
          </>
        );
      }, contentQuery)}
    />
  );
}
