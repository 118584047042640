import React from 'react';
import { Anchor } from 'grommet';
import AnchorArrowIcon from './AnchorArrowIcon';

export default function(props) {
  const { label, ...rest } = props;

  return (
    <Anchor
      label={
        <span>
          {label}
          <AnchorArrowIcon style={{ marginLeft: '0.5em' }} />
        </span>
      }
      {...rest}
    />
  );
}
