import React from 'react';
import { Box, Heading, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';

const StyledTextContainer = styled(Box)`
  width: 100%;
`;

export default function TextSectionCard({ name, text, htmlSerializer }) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const margin = sizeResponsive === 'small' ? { horizontal: 'auto', top: '20px' } : '10px';
        const mobileWidth = sizeResponsive === 'small' ? '100%' : '31%';
        return (
          <Box width={mobileWidth} border="all" pad="medium" background="white" margin={margin}>
            <Box pad={{ vertical: 'small' }}>
              <Heading margin="0" level={4}>
                {name}
              </Heading>
            </Box>
            <Box border={{ color: 'brand', side: 'bottom', size: '2px' }} />
            <Box direction="row" pad={{ top: '0px', bottom: 'small' }}>
              <StyledTextContainer width="100%">
                <RichText render={text} htmlSerializer={htmlSerializer} />
              </StyledTextContainer>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
