import React from 'react';

export default function({ ...rest }) {
  return (
    <svg viewBox="0 0 40 40" width="32" height="32" {...rest}>
      <path
        d="M6.805 12.44H2m4.805 0a4.667 4.667 0 0 1 9.323 0m-9.323 0a4.667 4.667 0 1 0 9.322 0m0 0h22.017M24.139 27.107H2m22.139 0a4.667 4.667 0 0 1 9.322 0m-9.322 0a4.667 4.667 0 1 0 9.323 0m0 0h4.682"
        stroke="#333"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
}
