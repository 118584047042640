import React from 'react';
import styled from 'styled-components';
import { Box, Image, Text } from 'grommet';
import { RichText } from 'prismic-reactjs';

const StyledTitleText = styled(Text)`
  font-size: ${(props) => (props.size === 'small' ? '44px' : '64px')};
  padding-bottom: ${(props) => (props.size === 'small' ? '15px' : '30px')};
  font-weight: 500;
  letter-spacing: ${(props) => (props.size === 'small' ? '-1px' : '-1.45px')};
  line-height: ${(props) => (props.size === 'small' ? '44px' : '68px')};
`;

const StyledTitleBlurb = styled(Text)`
  p {
    margin: 0;
    font-size: ${(props) => (props.size === 'small' ? '16px' : '18px')};
    letter-spacing: ${(props) => (props.size === 'small' ? '0.09px' : '0.1px')};
    line-height: ${(props) => (props.size === 'small' ? '28px' : '30px')};
  }
`;

export default function({ size, header, blurb, background, htmlSerializer }) {
  if (size === 'small') {
    return (
      <Box>
        <Image fit="cover" src={background} />
        <Box pad="medium" margin="small">
          <StyledTitleText size={size}>{header}</StyledTitleText>
          <StyledTitleBlurb size={size}>
            <RichText render={blurb} htmlSerializer={htmlSerializer} />
          </StyledTitleBlurb>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box background={`url(${background})`}>
        <Box pad="large" margin="xlarge" direction="column" background="white" width="588px">
          <StyledTitleText size={size} align="left">
            {header}
          </StyledTitleText>
          <StyledTitleBlurb size={size}>
            <RichText render={blurb} htmlSerializer={htmlSerializer} />
          </StyledTitleBlurb>
        </Box>
      </Box>
    </Box>
  );
}
