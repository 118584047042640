import styled from 'styled-components';

export default styled.video.attrs({
  autoPlay: true,
  loop: true,
  playsInline: true,
  muted: true,
})`
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
