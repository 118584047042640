import React from 'react';
import { Box, Text, Heading } from 'grommet';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import GatsbyAnchor from '../GatsbyAnchor';

const JobLocation = styled(Text)`
  font-family: Roboto, sans-serif;
  font-style: italic;
`;

const StyledAnchor = styled(GatsbyAnchor)`
  font-size: 15px;
  color: #0d7ea0;
`;

export default function({
  Link,
  size,
  jobTitle,
  jobLocation,
  jobDescription,
  jobUrl,
  htmlSerializer,
}) {
  return (
    <Box>
      <Heading level="6" margin={{ top: 'small', bottom: 'none' }} size={size}>
        {jobTitle}
      </Heading>
      <JobLocation color="dark-2" margin={{ top: 'small', bottom: 'none' }} size={size}>
        {jobLocation}
      </JobLocation>
      <Text size={size}>
        <RichText render={jobDescription} htmlSerializer={htmlSerializer} />
        <StyledAnchor Link={Link} label="Learn more" href={jobUrl} />
      </Text>
    </Box>
  );
}
