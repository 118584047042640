import React from 'react';
import { Box, Heading } from 'grommet';
import HotJobsCarousel from '../HotJobsCarousel';
import HotJobsCard from '../HotJobsCard';
import 'pure-react-carousel/dist/react-carousel.es.css';

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'medium', vertical: 'large' };
    case 'medium':
      return 'large';
    default:
      return 'xlarge';
  }
}

export default function({ Link, size, header, jobs, htmlSerializer }) {
  const isSmall = size === 'small';
  const isMedium = size === 'medium';

  return (
    <Box background="light-3" direction="column" pad={mainPadding(size)}>
      <Box
        width="1440px"
        margin="auto"
        direction={isSmall || isMedium ? 'column' : 'row'}
        gap="medium"
      >
        <Box basis={isSmall || isMedium ? 'full' : '1/4'} flex>
          <Heading margin={{ top: 'none', bottom: 'medium' }} size={size} level="2">
            {header}
          </Heading>
        </Box>
        {isSmall ? (
          <HotJobsCarousel size={size} allJobs={jobs} htmlSerializer={htmlSerializer} />
        ) : (
          <Box gap="medium" direction="row" basis={isMedium ? 'full' : '3/4'}>
            {jobs.map((elem) => (
              <Box key={elem.id} basis="1/3">
                <HotJobsCard
                  Link={Link}
                  htmlSerializer={htmlSerializer}
                  jobDescription={elem.description}
                  jobLocation={elem.location}
                  jobTitle={elem.jobTitle}
                  jobUrl={elem.url}
                  size={size}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
