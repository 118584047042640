import React, { useState } from 'react';
import { Search } from 'grommet-icons';
import { Box, TextInput } from 'grommet';

export default function(props) {
  const { onChange, ...rest } = props;
  const [value, setValue] = useState('');

  return (
    <Box align="center" direction="row" pad={{ left: 'small' }} background="white">
      <TextInput
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e);
        }}
        value={value || ''}
        type="search"
        {...rest}
      />
      <Search color="neutral-2" size="medium" />
    </Box>
  );
}
