import React from 'react';
import styled, { css } from 'styled-components';
import { Search } from 'grommet-icons';
import { Algolia } from '@styled-icons/fa-brands';

export const Root = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1em;
  color: ${(props) => props.theme.textColor};
`;

export const SearchIcon = styled(Search)`
  pointer-events: none;
  display: flex;
  align-self: center;
  margin-right: 0.6rem;
`;

const focus = css`
  background: white;
  color: ${(props) => props.theme.darkerBlue};
  cursor: text;
  width: 5em;
  + ${SearchIcon} {
    color: ${(props) => props.theme.darkerBlue};
    margin: 0.3em;
  }
`;

const collapse = css`
  width: 0;
  cursor: pointer;
  color: ${(props) => props.theme.lighterBlue};
  + ${SearchIcon} {
    color: white;
  }
  ${(props) => props.focus && focus}
  margin-right: ${(props) => (props.focus ? `` : `-1.2em`)};
  padding-right: ${(props) => (props.focus ? `` : `1.2em`)};
  ::placeholder {
    color: ${(props) => props.theme.gray};
  }
`;

const expand = css`
  + ${SearchIcon} {
    margin: 0.3em;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Input = styled.input`
  width: 60%;
  height: 30px;
  outline: none;
  border: none;
  font-size: 1em;
  background-color: ${(props) => (props.mobile ? `#272727 !important` : `transparent !important;`)};
  color: ${(props) => (props.mobile ? `#fff` : `#272727`)};
  transition: ${(props) => props.theme.shortTrans};
  border-radius: ${(props) => props.theme.smallBorderRadius};
  ${(props) => (props.collapse ? collapse : expand)};
  &::placeholder {
    opacity: 1;
    color: ${(props) => (props.mobile ? `#d9d9d6;` : `#272727`)};
  }
`;

export const SearchPageInput = styled.input`
  width: 100%;
  height: 42px;
  outline: none;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 0 0 2px 0 black;
  padding-left: 24px;
  font-size: 1.1em;
  background-color: ${(props) => (props.mobile ? `#272727 !important` : `transparent !important;`)};
  color: ${(props) => (props.mobile ? `#fff` : `#272727`)};
  transition: ${(props) => props.theme.shortTrans};
  border-radius: ${(props) => props.theme.smallBorderRadius};
  ${(props) => (props.collapse ? collapse : expand)};
  &::placeholder {
    opacity: 1;
    color: ${(props) => (props.mobile ? `#d9d9d6;` : `#272727`)};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid #fff;
  padding: 10px 20px;
  @media (max-width: 1023px) {
    justify-content: space-between;
  }
`;

export const ResultsWrapper = styled.div`
  min-width: 24%;
  max-width: 24%;
  margin-right: 1rem;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const HitsWrapper = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
  }
  margin: 4rem auto;
  display: ${(props) => (props.show ? `flex` : `none`)};
  background: white;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  position: ${(props) => (props.mobile ? `unset` : null)};
  max-width: 100vw;
  padding: 0.7em 1em 0.4em;
  color: #272727;
  border-radius: ${(props) => props.theme.smallBorderRadius};
  li + li {
    margin-top: 0.7em;
    padding-top: 0.7em;
    border-top: 1px solid ${(props) => props.theme.lighterGray};
  }
  * {
    margin-top: 0;
    padding: 0;
  }
  ul {
    list-style: none;
  }
  mark {
    color: ${(props) => props.theme.lighterBlue};
    background: ${(props) => props.theme.darkerBlue};
  }
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3em;
    h3 {
      color: black;
      background: ${(props) => props.theme.gray};
      padding: 0.1em 0;
      border-radius: ${(props) => props.theme.smallBorderRadius};
    }
  }
  h3 {
    margin: 0 0 0.5em;
  }
  h4 {
    margin-bottom: 0.3em;
  }
`;

export const PoweredBy = () => (
  <span css="font-size: 0.6em; text-align: end; padding: 0;">
    Powered by
    <a href="https://algolia.com">
      <Algolia size="1em" /> Algolia
    </a>
  </span>
);
