import React from 'react';
import { Anchor, Box, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import GatsbyAnchor from '../../components/GatsbyAnchor';
import GatsbyButton from '../../components/GatsbyButton';
import SocialIcons from '../../components/SocialIcons';
import EyebrowText from '../../typography/EyebrowText';
import BodyText from '../../typography/BodyText';
// import USGBCLogo from '../../components/USGBCLogo';

const StyledBox = styled(Box)`
  font-size: 15px;

  a {
    letter-spacing: 0.08px;
    line-height: 30px;
  }
`;

const StyledRichText = styled(BodyText)`
  p {
    margin: 0;
    font-size: 15px;
  }
  width: 100%;
`;

const StyledEyebrowText = styled(EyebrowText)`
  letter-spacing: 0.08px;
  line-height: 28px;
`;

const StyledCopywrite = styled(Box)`
  letter-spacing: 0.08px;
`;

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { vertical: '50px', horizontal: 'medium' };
    case 'medium':
      return { vertical: '50px', horizontal: 'large' };
    default:
      return { vertical: '50px', horizontal: 'xlarge' };
  }
}

export default function Footer({ Link, content, htmlSerializer }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';
        const isMedium = size === 'medium';

        const phoneNumber = isSmall ? (
          <Anchor
            label={`P: ${content.phone_number}`}
            href={`tel:${content.phone_number}`}
            margin={{ bottom: '10px' }}
          />
        ) : (
          <BodyText size="15px" color="white" margin={{ bottom: '10px' }}>
            P: {content.phone_number}
          </BodyText>
        );
        const align = isSmall ? 'center' : 'start';
        const usgbcBorder = isSmall ? 'top' : 'left';
        const copywriteBorder = isSmall ? 'top' : '';

        return (
          <StyledBox as="footer">
            <Box background={{ color: 'dark-1' }} pad={mainPadding(size)}>
              <Box width="1440px" margin="auto">
                <Box justify="between" direction="row-responsive">
                  <Box align={align} flex={false} margin={{ right: 'large' }}>
                    <StyledEyebrowText color="white" margin={{ vertical: '20px' }}>
                      {content.contact_title}
                    </StyledEyebrowText>
                    <GatsbyButton Link={Link} href="/contact" label="Contact Us" />
                    <GatsbyAnchor
                      Link={Link}
                      href="/locations"
                      primary
                      label={content.find_location}
                      margin={{ vertical: '20px' }}
                    />
                    {phoneNumber}
                    <BodyText size="15px" color="white" margin={{ bottom: '10px' }}>
                      F: {content.fax_number}
                    </BodyText>
                    <SocialIcons
                      links={{
                        instagram: content.instagram_link.url,
                        linkedin: content.linkedin_link.url,
                        twitter: content.twitter_link.url,
                        youtube: content.youtube_link.url,
                        glassdoor: content.glassdoor_link.url,
                      }}
                    />
                  </Box>
                  <Box align={align} margin={{ right: 'large' }} flex="shrink">
                    <StyledEyebrowText color="white" margin={{ vertical: '20px' }}>
                      {content.links_title}
                    </StyledEyebrowText>
                    {content.links.map((item) => {
                      return (
                        <GatsbyAnchor
                          Link={Link}
                          key={item.link_url.url}
                          href={item.link_url.url.replace('https:///', '/')}
                          primary
                          label={item.link_text}
                          margin={{ vertical: '5px' }}
                        />
                      );
                    })}
                    <GatsbyAnchor
                      href="/scam-warning"
                      label="Warning About Recruitment Scams"
                      primary
                    />
                  </Box>
                  <Box width={isSmall ? 'full' : 'medium'} align={align} flex="shrink">
                    <StyledEyebrowText color="white" margin={{ vertical: '20px' }}>
                      {content.registration_title}
                    </StyledEyebrowText>
                    <StyledRichText textAlign={align} color="white">
                      <RichText
                        render={content.registration_text}
                        htmlSerializer={htmlSerializer}
                        size="10px"
                      />
                    </StyledRichText>
                  </Box>
                  {!isSmall ? (
                    <>
                      <Box
                        border={usgbcBorder}
                        margin={
                          isSmall
                            ? { horizontal: 'medium', vertical: 'large' }
                            : { horizontal: 'large' }
                        }
                      />
                      <Box width={isMedium ? 'none' : 'medium'} align="center" flex="shrink">
                        <Box margin={{ top: '50px' }}>
                          <StyledCopywrite margin={{ vertical: '20px' }}>
                            ©{new Date().getFullYear()} POWER Engineers, Incorporated. All rights
                            reserved.
                          </StyledCopywrite>
                        </Box>
                        {/* <StyledRichText color="white" textAlign="center">
                      <RichText
                      render={content.certification_text}
                      htmlSerializer={htmlSerializer}
                      />
                    </StyledRichText> */}
                      </Box>
                    </>
                  ) : null}
                </Box>
                {isSmall ? (
                  <BodyText
                    size="15px"
                    textAlign={align}
                    color="white"
                    margin={{ vertical: 'medium' }}
                  >
                    <Box border={copywriteBorder}>
                      <StyledCopywrite margin={{ vertical: '20px' }}>
                        ©{new Date().getFullYear()} POWER Engineers, Incorporated. All rights
                        reserved.
                      </StyledCopywrite>
                    </Box>
                  </BodyText>
                ) : null}
              </Box>
            </Box>
            <CookieConsent
              location="bottom"
              buttonText="I Agree"
              cookieName="cookieConsent"
              buttonStyle={{ background: '#272727', color: '#ffffff', fontSize: '16px' }}
              style={{
                background: '#cb361d',
                color: '#ffffff',
                fontFamily: 'Roboto Slab,serif',
                fontSize: '16px',
              }}
            >
              FYI - We use cookies to optimize your experience, analyze traffic and personalize
              content. To learn more, please see our{' '}
              <GatsbyAnchor Link={Link} href="/privacy-policy" style={{ color: 'inherit' }}>
                Privacy Policy
              </GatsbyAnchor>
              .{' '}
              <GatsbyAnchor Link={Link} href="/privacy-policy" style={{ color: 'inherit' }}>
                Learn More
              </GatsbyAnchor>
            </CookieConsent>
          </StyledBox>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
