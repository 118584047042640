/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledP = styled.p`
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.08px;
  line-height: 22px;
  color: #727272;
`;

const StyledDiv = styled.div`
  margin: auto;
  width: 100%;

  iframe {
    width: 100% !important;
  }
`;

export default function() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const hubSpotScript = document.createElement('script');
    hubSpotScript.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(hubSpotScript);

    hubSpotScript.addEventListener('load', () => {
      if (window.hbspt && !loaded) {
        setLoaded(true);

        window.hbspt.forms.create({
          portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
          formId: process.env.GATSBY_CONTACT_US_ID,
          target: '#hubspotForm',
          onFormReady: ($form) => {
            $form.parents('body').css({
              color: '#fff',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '1.125rem',
            });
          },
        });
      }
    });

    return () => {
      document.body.removeChild(hubSpotScript);
    };
  }, []);

  return (
    <>
      <StyledP>* Required Fields</StyledP>
      <StyledDiv id="hubspotForm" />
    </>
  );
}
