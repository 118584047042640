import React from 'react';
import styled from 'styled-components';
import { Box, Image, ResponsiveContext } from 'grommet';
import GatsbyAnchor from '../GatsbyAnchor';
import AnchorArrowIcon from '../AnchorArrow/AnchorArrowIcon';

const StyledAnchor = styled(GatsbyAnchor)`
  color: #333;
  display: flex;
  flex-direction: row;
  font-family: Roboto, sans-serif;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 22px;
  min-height: 75px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.1s;
  overflow: hidden;

  &.active,
  &:hover,
  &:focus {
    color: #0d7ea0;
  }

  svg {
    width: 22px;
    flex: 0 0 22px;
    justify-self: end;
  }
`;

export default function CarouselCard({ Link, image, href, name }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const scaledBasis = size === 'small' ? '100%' : '1/4';

        return (
          <Box basis={scaledBasis} pad="small" align="center" fill>
            <Box border="all" fill>
              {image && <Image fit="cover" src={image.url || ''} alt={image.alt || ''} />}
              <StyledAnchor
                Link={Link}
                href={href}
                label={
                  <>
                    {name}
                    <AnchorArrowIcon style={{ marginLeft: '0.5em' }} />
                  </>
                }
                margin={{ horizontal: 'medium' }}
              />
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
