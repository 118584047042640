import React from 'react';
import { Box, Text, Heading, ResponsiveContext, Paragraph } from 'grommet';
import { Next, Previous } from 'grommet-icons';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import TextSectionCard from './TextSectionCard';
import 'pure-react-carousel/dist/react-carousel.es.css';

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'small', vertical: 'xlarge' };
    case 'medium':
      return 'large';
    default:
      return 'xlarge';
  }
}

function centeredText(size) {
  switch (size) {
    case 'small':
      return '100%';
    case 'medium':
      return '100%';
    default:
      return '66%';
  }
}

const StyledDots = styled(DotGroup)`
  button {
    height: 15px;
    width: 15px;
    margin-right: 15px;
    border-color: #fff;
    background-color: #272727;
  }
  .carousel__dot--selected {
    background-color: #fff;
  }
`;
const StyledButtonBack = styled(ButtonBack)`
  border: none;
  background-color: #272727;

  &:focus {
    outline: none;
  }
`;
const StyledButtonNext = styled(ButtonNext)`
  border: none;
  background-color: #272727;
  &:focus {
    outline: none;
  }
`;

export default function TextSection({ heading, body, textSectionCards, htmlSerializer }) {
  const CarouselCards = textSectionCards.map((data, index) => (
    <Slide index={index} key={data.name}>
      <TextSectionCard
        name={data.name}
        text={data.text}
        key={Math.random()}
        htmlSerializer={htmlSerializer}
      />
    </Slide>
  ));
  const Cards = textSectionCards.map((data) => (
    <TextSectionCard
      name={data.name}
      text={data.text}
      key={Math.random()}
      htmlSerializer={htmlSerializer}
    />
  ));
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        return (
          <Box background="dark-1">
            <Box width="1440px" margin="auto" pad={mainPadding(sizeResponsive)} justify="center">
              <Heading size={sizeResponsive} level={2} margin="xsmall" alignSelf="center">
                {heading}
              </Heading>
              <Box margin="auto" width={centeredText(sizeResponsive)}>
                <Text textAlign="center">
                  {Array.isArray(body) ? (
                    <RichText render={body} htmlSerializer={htmlSerializer} size={sizeResponsive} />
                  ) : (
                    <Paragraph size={sizeResponsive} fill>
                      {body}
                    </Paragraph>
                  )}
                </Text>
              </Box>

              <ResponsiveContext.Consumer>
                {() => {
                  if (sizeResponsive === 'small') {
                    return (
                      <Box>
                        <CarouselProvider
                          naturalSlideWidth={100}
                          naturalSlideHeight={100}
                          totalSlides={6}
                          visibleSlides={1}
                          lockOnWindowScroll
                        >
                          <Box
                            height="425px"
                            width="345px"
                            margin={{ horizontal: 'auto' }}
                            direction="row"
                          >
                            <Slider style={{ height: '100%', width: '100%' }}>
                              {CarouselCards}
                            </Slider>
                          </Box>
                          <Box direction="row" justify="between">
                            <StyledButtonBack>
                              <Previous />
                            </StyledButtonBack>
                            <StyledDots disableActiveDots={false} />
                            <StyledButtonNext>
                              <Next />
                            </StyledButtonNext>
                          </Box>
                        </CarouselProvider>
                      </Box>
                    );
                  }
                  return (
                    <Box direction="row-responsive" justify="center" wrap>
                      {Cards}
                    </Box>
                  );
                }}
              </ResponsiveContext.Consumer>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
