import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Next, Previous } from 'grommet-icons';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import styled from 'styled-components';
import AboutCard from './AboutCard';

import 'pure-react-carousel/dist/react-carousel.es.css';

const StyledDots = styled(DotGroup)`
  button {
    height: 15px;
    width: 15px;
    margin-right: 10px;
    border-color: white;
    background-color: transparent;
    border-style: solid;
    border-image-source: none;
  }
  .carousel__dot--selected {
    background-color: white;
  }
`;

const StyledButtonBack = styled(ButtonBack)`
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: 600px) {
    svg {
      height: 40px;
      width: 40px;
    }
  }

  @media screen and (min-width: 1023px) {
    svg {
      height: 50px;
      width: 50px;
    }
  }
`;

const StyledButtonNext = styled(ButtonNext)`
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }

  @media screen and (min-width: 600px) {
    svg {
      height: 40px;
      width: 40px;
    }
  }

  @media screen and (min-width: 1023px) {
    svg {
      height: 50px;
      width: 50px;
    }
  }
`;

const StyledContainerBox = styled(Box)`
  width: 1440px;
`;

export default function AboutCarousel({ backgroundImage, carouselContent }) {
  const totalSlides = carouselContent.length;
  const carouselSlides = carouselContent.map((slides, index) => (
    <Slide key={slides.id} index={index}>
      <AboutCard
        authorImage={slides.authorImage}
        authorName={slides.authorName}
        authorQuote={slides.authorQuote}
        authorTitle={slides.authorTitle}
      />
    </Slide>
  ));
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const slideHeightValue = {
          xsmall: 215,
          small: 175,
          large: 50,
        };
        const slideHeight = slideHeightValue[size];
        const visibleSlides = 1;

        return (
          <Box background={`url(${backgroundImage})`} pad={{ vertical: '50px' }}>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={slideHeight}
              totalSlides={totalSlides}
              visibleSlides={visibleSlides}
              lockOnWindowScroll
            >
              <StyledContainerBox
                margin={{ horizontal: 'auto' }}
                direction="row"
                justify="between"
                align="center"
              >
                <StyledButtonBack>
                  <Previous height="50px" width="50px" color="white" />
                </StyledButtonBack>
                <Slider style={{ height: '100%', width: '100%' }}>{carouselSlides}</Slider>
                <StyledButtonNext>
                  <Next color="white" />
                </StyledButtonNext>
              </StyledContainerBox>
              <Box direction="row" justify="center">
                <StyledDots disableActiveDots={false} />
              </Box>
            </CarouselProvider>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
