import React from 'react';
import styled from 'styled-components';

const KeywordList = styled.ul`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: start;

  &:after {
    content: '';
    flex: 1 1;
  }
`;

const Keyword = styled.li`
  align-items: center;
  border: 1px solid #0d7ea0;
  box-sizing: border-box;
  color: #0d7ea0;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 16px;
  min-height: 60px;
  width: calc(50% - 12px);
  margin-right: 12px;
  padding: 17px 12px;

  ${({ active }) =>
    active ? 'border-color: #272727; color: #272727; cursor: default; font-weight: 700;' : ''}

  &[disabled] {
    border-color: #d9d9d6;
    color: #272727;
    cursor: default;
  }

  @media screen and (min-width: 1023px) {
    width: calc(33.333% - 12px);
  }
`;

export default function Keywords({ proofs, activeProof, setProof }) {
  return (
    <KeywordList>
      {proofs &&
        proofs.map(
          ({ keyword, proof }) =>
            keyword && (
              <Keyword
                key={keyword}
                active={proof && activeProof._meta.uid === proof._meta.uid}
                onClick={() => proof && setProof(proof)}
                disabled={!proof}
              >
                {keyword}
              </Keyword>
            ),
        )}
    </KeywordList>
  );
}
