import React from 'react';
import { Box, Heading } from 'grommet';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';
import FormHeader from '../FormHeader';
import FormIframe from './FormIframe';

const StyledContainer = styled.div`
  max-width: 1440px;
  margin: 0 15px;
`;

export default function({
  size,
  body,
  cardOneHeading,
  cardOneTo,
  cardOneButtonLabel,
  cardTwoHeading,
  cardTwoTo,
  cardTwoButtonLabel,
  heading,
  htmlSerializer,
}) {
  const boxPad = size === 'small' ? '20px' : '36px';
  const direction = size === 'small' ? 'column' : 'row-responsive';
  const marginRight = size === 'small' ? { vertical: '10px' } : { right: '10px' };
  const marginLeft = size === 'small' ? { vertical: '10px' } : { left: '10px' };

  return (
    <StyledContainer align="center" margin={{ horizontal: '15px' }}>
      <Box background="light-1" pad={{ vertical: 'large' }}>
        <Box pad={{ horizontal: 'large' }} width="" align="center">
          <FormHeader size={size} header={heading} text={body} htmlSerializer={htmlSerializer} />
          <FormIframe />
        </Box>
      </Box>
      <Box direction={direction} justify="between" margin={{ vertical: '25px' }}>
        <Box background="white" basis="1/2" margin={marginRight} pad={boxPad} justify="between">
          <Heading size={size} level="4">
            {cardOneHeading}
          </Heading>
          <div>
            <GatsbyButton href={cardOneTo} label={cardOneButtonLabel} />
          </div>
        </Box>
        <Box background="white" basis="1/2" margin={marginLeft} pad={boxPad} justify="between">
          <Heading size={size} level="4">
            {cardTwoHeading}
          </Heading>
          <div>
            <GatsbyButton href={cardTwoTo} label={cardTwoButtonLabel} />
          </div>
        </Box>
      </Box>
    </StyledContainer>
  );
}
