import React from 'react';
import styled from 'styled-components';
import { Box, Image, ResponsiveContext } from 'grommet';
import GatsbyAnchor from '../GatsbyAnchor';
import AnchorArrowIcon from '../AnchorArrow/AnchorArrowIcon';

const LinkBox = styled(Box)`
  border-top: 1px #fff solid;
  height: 100px;
  padding: 10px 20px;
  &:hover {
    border-top: 1px #d8d8d8 solid;
    background: #272727;
    color: #fff;
  }
  span {
    max-width: 210px;
  }
`;

const MobileBox = styled(Box)`
  height: 60px;
  &:hover {
    background: #272727;
    color: #fff;
  }
`;

const AnchorWithHover = styled(GatsbyAnchor)`
  width: 100%;
  color: #272727;
  text-decoration: none;
  &:hover {
    background: #272727;
    color: #fff;
  }
  &:hover .anchorHover {
    color: #fff;
  }
`;

const StyledImage = styled(Image)`
  flex: none;
`;

export default function SectionCard(props) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';
        const isMedium = size === 'medium';

        // mobile view
        if (isSmall) {
          return (
            <AnchorWithHover href={props.href} margin={{ vertical: 'xsmall' }}>
              <MobileBox
                direction="row"
                pad="20px"
                align="center"
                alignContent="end"
                justify="between"
                border="all"
              >
                {props.label}
                <AnchorArrowIcon width="30" height="15" className="anchorHover" />
              </MobileBox>
            </AnchorWithHover>
          );
        }

        // desktop view
        return (
          <Box pad="small" width={isMedium ? '33.333%' : '25%'}>
            <Box border="all" flex="grow">
              <StyledImage
                fit="cover"
                src={props.serviceImage.url}
                alt={props.serviceImage.alt || ''}
              />
              <AnchorWithHover Link={props.Link} height="80" href={`${props.href}`}>
                <LinkBox
                  direction="row"
                  basis="1/4"
                  align="center"
                  alignContent="between"
                  justify="between"
                >
                  <span>{props.label}</span>
                  <AnchorArrowIcon width="30" height="15" className="anchorHover" />
                </LinkBox>
              </AnchorWithHover>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
