export function forceSlashes(str) {
  const url = !str.startsWith('http') && !str.startsWith('/') ? `/${str}` : str;
  return !url.endsWith('/') && !url.includes('#') && !url.includes('?') ? `${str}/` : str;
}

export function isAnchor(str) {
  return str.includes('#');
}

export function isEmail(str) {
  return str.startsWith('mailto');
}

export function isExternal(str) {
  return str.startsWith('http');
}

export function parseQueryString(url) {
  if (!url.split('?').length) return null;

  const query = url.split('?')[1];

  return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
    const result = params;
    const [key, value] = param.split('=');
    result[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    return result;
  }, {});
}

export function createQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');
}
