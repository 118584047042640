import React from 'react';
import { Box, Heading, Image, ResponsiveContext, Paragraph } from 'grommet';
import { RichText } from 'prismic-reactjs';
import { mainPadding, mainGap } from '../../utils/layout';

export default function ServicesL3Hero({
  image,
  bodyText,
  headerText,
  htmlSerializer,
  contactLink,
}) {
  // bodyText[0].spans = [];
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        return (
          <Box width="1440px" margin="auto" pad={mainPadding(sizeResponsive)}>
            <Heading
              margin={{ top: '0px' }}
              size={sizeResponsive}
              level={1}
              direction="row-responsive"
              alignSelf="center"
            >
              {headerText}
            </Heading>

            <Box direction="row-responsive" gap={mainGap(sizeResponsive)}>
              <Box basis="1/2" direction="column">
                {Array.isArray(bodyText) ? (
                  <RichText
                    render={bodyText}
                    htmlSerializer={htmlSerializer}
                    size={sizeResponsive}
                  />
                ) : (
                  <Paragraph margin="0" size={sizeResponsive} fill>
                    {bodyText}
                  </Paragraph>
                )}
                {contactLink ? contactLink() : null}
              </Box>
              <Box basis="1/2" responsive>
                <Image width="100%" src={image.url} alt={image.alt || ''} />
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
