import React, { useState, useEffect, createRef } from 'react';
import { InstantSearch, Index, Hits, connectStateResults } from 'react-instantsearch-dom';
import styled from 'styled-components';
import algoliasearch from 'algoliasearch/lite';
import { ResultsWrapper, HitsWrapper, PoweredBy } from './styles';
import Input from './Input';
import * as hitComps from './hitComps';

const Results = connectStateResults(({ searchState: state, searchResults: res, children }) =>
  res && res.nbHits > 0 ? children : `No results for '${state.query}'`,
);

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`,
);

const useClickOutside = (ref, handler, events) => {
  const eventsList = events || [`mousedown`, `touchstart`];
  const detectClickOutside = (event) => !ref?.current?.contains(event.target) && handler();

  useEffect(() => {
    eventsList.forEach((event) => {
      document.addEventListener(event, detectClickOutside, false);
    });

    return () => {
      eventsList.forEach((event) => {
        document.removeEventListener(event, detectClickOutside, false);
      });
    };
  });
};

export const InputWrapper = styled.div`
  max-width: 42rem;
  margin: 0 auto;
`;

export default function({ queryParams, searchPage, indices, collapse, hitsAsGrid, mobile }) {
  const ref = createRef();
  const [query, setQuery] = useState(queryParams ? Object.values(queryParams) : null);
  const [focus, setFocus] = useState(false);

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  );
  useClickOutside(ref, () => setFocus(false));
  const searchState = {
    query,
  };

  return (
    <div ref={ref}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query: q }) => (q.length >= 2 ? setQuery(q) : null)}
        searchState={searchState}
      >
        <InputWrapper>
          <Input
            mobile={mobile}
            onFocus={() => setFocus(true)}
            {...{ collapse, focus }}
            queryParams={queryParams}
            searchPage={searchPage}
          />
        </InputWrapper>
        {searchPage && query[0] !== '' ? (
          <>
            <HitsWrapper mobile={mobile} show={query.length} asGrid={hitsAsGrid}>
              {indices.map(({ name, title, hitComp }) => (
                <ResultsWrapper>
                  <Index key={name} indexName={name}>
                    {/* Results shows here */}
                    <header>
                      <h3>{title}</h3>
                      <Stats />
                    </header>
                    <Results>
                      <Hits
                        hitComponent={hitComps[hitComp]((e) => {
                          e.stopPropagaton();
                          setFocus(false);
                        })}
                      />
                    </Results>
                  </Index>
                </ResultsWrapper>
              ))}
            </HitsWrapper>
            <PoweredBy />
          </>
        ) : null}
      </InstantSearch>
    </div>
  );
}
