import React from 'react';
import { Box, Heading, ResponsiveContext, Paragraph } from 'grommet';
import styled from 'styled-components';
import { EyebrowText } from '../../index';
import LibraryCard from './LibraryCard';
import GatsbyButton from '../../components/GatsbyButton';
import { mainPadding } from '../../utils/layout';

const StyledParagraph = styled(Paragraph)`
  max-width: 600px;
`;

export default function LibraryBlock({
  Link,
  bodyText,
  eyebrowText,
  exploreTag,
  headingLevel,
  libraryCards,
  titleText,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const isSmall = sizeResponsive === 'small';
        const eyebrowMargin = sizeResponsive === 'small' ? {} : { bottom: '25px' };

        return (
          <Box as="section" pad={mainPadding(sizeResponsive)}>
            <Box width="1440px" margin="auto">
              <Box align="center" width={isSmall ? 'full' : '50%'} margin="auto">
                {eyebrowText && (
                  <EyebrowText color="accent-2" size={sizeResponsive} margin={eyebrowMargin}>
                    {eyebrowText}
                  </EyebrowText>
                )}
                {titleText && (
                  <Heading
                    color="dark-1"
                    level={headingLevel || '2'}
                    margin={{ top: 'none', bottom: 'medium' }}
                    size={sizeResponsive}
                    textAlign="center"
                  >
                    {titleText}
                  </Heading>
                )}
                {bodyText && (
                  <StyledParagraph
                    textAlign="center"
                    margin={{ bottom: 'large' }}
                    size={sizeResponsive}
                  >
                    {bodyText}
                  </StyledParagraph>
                )}
              </Box>
              {libraryCards && (
                <Box direction="row-responsive" align="start" gap={isSmall ? 'large' : null}>
                  {libraryCards.map((card) => (
                    <LibraryCard Link={Link} key={card.id} library={card} />
                  ))}
                </Box>
              )}
              {exploreTag && (
                <Box
                  align="center"
                  border={{ side: 'top', color: '#979797' }}
                  margin={{ top: 'xlarge' }}
                >
                  <Heading
                    textAlign="center"
                    size={sizeResponsive}
                    level="6"
                    margin={{ vertical: 'large' }}
                  >
                    {exploreTag}
                  </Heading>
                  <GatsbyButton Link={Link} primary label="Explore Our Library" href="/library" />
                </Box>
              )}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
