import React from 'react';
import { Box, ResponsiveContext, Heading } from 'grommet';
import CapabilitiesCarousel from '../../components/CapabilitiesCarousel';
import MarketServicesCard from '../../components/MarketServicesCard';
import { Cta } from '../../index';
import CenteredText from '../AboutBlock/CenteredText';

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'medium', top: 'xlarge', bottom: 'xsmall' };
    case 'medium':
      return { top: 'large', horizontal: 'large', bottom: 'small' };
    default:
      return { top: 'xlarge', horizontal: 'xlarge', bottom: 'small' };
  }
}

export default function FeaturedServices({
  Link,
  featuredServices,
  allServices,
  htmlSerializer,
  ...rest
}) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const gapMobile = sizeResponsive === 'small' ? 'xlarge' : 'medium';
        const padMobile = sizeResponsive === 'small' ? { bottom: 'xlarge' } : { bottom: 'xsmall' };
        return (
          <Box>
            <Box as="section" pad={mainPadding(sizeResponsive)} justify="center">
              <Box margin="auto" width="1440px">
                <Box margin={{ bottom: 'large' }}>
                  <CenteredText>
                    <Cta {...rest} />
                  </CenteredText>
                </Box>
                <Box gap={gapMobile} pad={padMobile} direction="row-responsive">
                  {featuredServices.map((item) => {
                    return (
                      <MarketServicesCard
                        Link={Link}
                        key={item.slug}
                        image={item.thumbnail}
                        title={item.name}
                        blurb={item.copy}
                        ctaText="Explore This Service"
                        ctaUrl={item.slug}
                        htmlSerializer={htmlSerializer}
                        size={sizeResponsive}
                      />
                    );
                  })}
                </Box>

                <Box margin={{ top: 'large', bottom: 'small' }} border="top" />
              </Box>
            </Box>

            <Box alignSelf="center" pad={{ horizontal: 'medium', top: 'small', bottom: 'xlarge' }}>
              <Box width="1440px">
                <Heading size={sizeResponsive} level="5" textAlign="center">
                  EXPLORE MORE SERVICES
                </Heading>
                <CapabilitiesCarousel Link={Link} carouselContent={allServices} />
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
