import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Next, Previous } from 'grommet-icons';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import styled from 'styled-components';
import CarouselCard from './CarouselCard';
import 'pure-react-carousel/dist/react-carousel.es.css';

const StyledDots = styled(DotGroup)`
  text-align: center;

  button {
    background: none;
    border: 1px solid #272727;
    height: 15px;
    margin: 0 7px;
    padding: 0;
    width: 15px;

    &.carousel__dot--selected {
      background-color: #272727;
    }
  }
`;

const StyledButtonBack = styled(ButtonBack)`
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
`;

const StyledButtonNext = styled(ButtonNext)`
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
`;

const slideCounts = {
  small: 1,
  medium: 2,
  large: 4,
  xlarge: 5,
};

export default function CapabilitiesCarousel({ Link, carouselContent }) {
  const totalSlides = carouselContent.length;
  const carouselSlides = carouselContent.map((slides, index) => (
    <Slide index={index} key={slides.name}>
      <CarouselCard
        Link={Link}
        href={slides.href}
        image={slides.image}
        key={slides.uid}
        name={slides.name}
      />
    </Slide>
  ));

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const visibleSlides = slideCounts[size];

        return (
          <Box>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={100}
              totalSlides={totalSlides}
              visibleSlides={visibleSlides}
              lockOnWindowScroll
            >
              <Box direction="row" justify="between" margin={{ bottom: 'medium' }} width="1440px">
                <StyledButtonBack>
                  <Previous
                    onClick={() => {
                      window.dataLayer.push({
                        event: 'GAEvent',
                        eventCategory: 'Explore more services',
                        eventAction: 'scroll left',
                        eventLabel: window.location.href,
                      });
                    }}
                    size="36px"
                  />
                </StyledButtonBack>
                <Slider style={{ height: '100%', width: '100%' }}>{carouselSlides}</Slider>
                <StyledButtonNext>
                  <Next
                    onClick={() => {
                      window.dataLayer.push({
                        event: 'GAEvent',
                        eventCategory: 'Explore more services',
                        eventAction: 'scroll right',
                        eventLabel: window.location.href,
                      });
                    }}
                    size="36px"
                  />
                </StyledButtonNext>
              </Box>
              <StyledDots disableActiveDots={false} />
            </CarouselProvider>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
