import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionPanel, Box, ResponsiveContext } from 'grommet';
import { CurrentsCard, MediaDownloadCard } from '../../index';

const StyledAccordionPanel = styled(AccordionPanel)`
  ${({ active }) => (active ? 'background: #C4EBF6;' : '')}
  position: relative;

  padding: 0 8%;

  h4 {
    color: #272727 !important;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    margin: 20px 0;
    text-transform: none;
  }

  svg {
    color: #272727 !important;
  }
`;

export default function AccordionBlock({ issues, Link, htmlSerializer }) {
  const [activeIndex, setActiveIndex] = useState([0]);
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const mobile =
          sizeResponsive === 'small'
            ? { vertical: 'large', horizontal: 'medium' }
            : { vertical: 'large', horizontal: 'xlarge' };
        return (
          <Accordion
            border="top"
            activeIndex={activeIndex}
            onActive={(index) => setActiveIndex(index)}
            multiple
          >
            {issues.map((issue, index) => {
              const active = activeIndex.includes(index);
              return (
                <StyledAccordionPanel
                  margin="auto"
                  label={issue.panel}
                  active={active}
                  onClick={() => {
                    window.dataLayer.push({
                      event: 'GAEvent',
                      eventCategory: 'Currents Interaction',
                      eventAction: 'Click',
                      eventLabel: issue.panel,
                    });
                  }}
                >
                  <Box margin="auto" as="section" width="1440px" pad={mobile}>
                    <Box wrap direction="row-responsive" justify="left">
                      {issue.download && <MediaDownloadCard {...issue.download} />}
                      {issue.articles.map((article) => {
                        return (
                          <CurrentsCard
                            Link={Link}
                            currents={article}
                            htmlSerializer={htmlSerializer}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </StyledAccordionPanel>
              );
            })}
          </Accordion>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
