import styled from 'styled-components';
import { Box } from 'grommet';

export default styled(Box).attrs(({ overflow }) => ({
  overflow: overflow || 'hidden',
}))`
  background-position: center center;
  background-size: cover;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : 'auto')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
  position: relative;

  & > *:not(video) {
    position: relative;
  }
`;
