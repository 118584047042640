import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Cta, Picture } from '../../index';
import { mainGap, mainPadding } from '../../utils/layout';

export default function CareersBlock({ bkgImage, image, stats, ...rest }) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const mobile = sizeResponsive === 'small';
        return (
          <Box
            as="section"
            background={{
              image: `url(${bkgImage})`,
              position: 'bottom',
            }}
            pad={mainPadding(sizeResponsive)}
            justify="center"
          >
            <Box
              gap={mainGap(sizeResponsive)}
              width="1440px"
              direction="row-responsive"
              margin="auto"
            >
              {mobile ? (
                <Box
                  justify="center"
                  margin="auto"
                  style={{ textAlign: 'center' }}
                  pad={{ bottom: '100px' }}
                >
                  <Cta {...rest} />
                </Box>
              ) : (
                <Box basis="1/3" justify="center" pad={{ bottom: '35px' }}>
                  <Cta {...rest} />
                </Box>
              )}
              {mobile ? (
                ''
              ) : (
                <Box basis="2/3">
                  <Picture width="100%" src={image.url} alt={image.alt || ''} />
                </Box>
              )}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
