import React from 'react';
import { Box, Text, Heading } from 'grommet';
import { RichText } from 'prismic-reactjs';
import GenericCard from '../GenericCard';

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'small', vertical: 'large' };
    case 'medium':
      return 'large';
    default:
      return 'xlarge';
  }
}

export default function({ Link, size, header, blurb, cards, htmlSerializer }) {
  return (
    <Box align="center" pad={mainPadding(size)}>
      <Heading margin="none" textAlign="center" size={size} level="2">
        {header}
      </Heading>
      <Box width="800px" margin={{ bottom: 'medium' }}>
        <Text color="dark-1" textAlign="center" size={size}>
          <RichText render={blurb} htmlSerializer={htmlSerializer} />
        </Text>
      </Box>
      <Box direction="row-responsive" width="1440px" margin="auto">
        {cards.map((elem) => (
          <GenericCard
            Link={Link}
            key={elem.id}
            image={elem.image}
            title={elem.title}
            blurb={elem.blurb}
            size={size}
            htmlSerializer={htmlSerializer}
          />
        ))}
      </Box>
    </Box>
  );
}
