/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Box, Button, Form, MaskedInput } from 'grommet';
import { Search } from 'grommet-icons';
import { mapDistance } from '../../utils/geo';

const apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

const SearchButton = styled(Button)`
  display: flex;
  padding: 6px 11px;
`;

async function fetchCoords({ zipCode, apiKey, locations, onSubmit }) {
  if (zipCode) {
    try {
      const res = await fetch(`${apiUrl}?key=${apiKey}&components=postal_code:${zipCode}`);
      const { results, error_message: error } = await res.json();

      if (error) throw new Error(error);
      if (!results.length) throw new Error('No results');

      const { location } = results[0].geometry;
      const result = mapDistance(location, locations);

      onSubmit({ zipCode, result });
    } catch (error) {
      onSubmit({ error });
    }
  }
}

export default function({ apiKey, locations, onSubmit, ...rest }) {
  const [inputValue, setInputValue] = useState();
  const [zipCode, setZipCode] = useState();
  const inputRef = useRef();

  useEffect(() => {
    if (onSubmit && zipCode) {
      fetchCoords({ zipCode, apiKey, locations, onSubmit });
      setInputValue('');
      setZipCode('');
    }
  }, [zipCode]);

  return (
    <Form onSubmit={() => setZipCode(inputValue)}>
      <Box align="center" border={{ side: 'all', color: 'border' }} direction="row" {...rest}>
        <MaskedInput
          mask={[
            {
              length: [2, 12],
              regexp: /^[a-zA-Z0-9\- ]{1,12}$/,
            },
          ]}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter ZIP Code"
          ref={inputRef}
          value={inputValue}
          plain
        />
        <SearchButton
          alignSelf="center"
          color="neutral-2"
          focusIndicator={false}
          icon={<Search color="neutral-2" size="30px" />}
          type="submit"
        />
      </Box>
    </Form>
  );
}
