import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import GatsbyButton from '../GatsbyButton';
import CRMForm from '../../layouts/PublicationContentBlock/CRMForm';

import { SignupDownload } from '../..';

const PublicationBodyContainer = styled(Box)`
  display: block;
  color: #272727;
  line-height: 1.667;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 0;
  }

  & p {
    line-height: 1.667;
  }

  figcaption {
    max-width: 300px;
    font-size: 14px;
    font-style: italic;

    p {
      line-height: 24px;
      padding-right: 5px;
      color: #4b4b4b;
    }
  }

  img {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .captioned {
    max-width: 300px;
  }

  span:nth-of-type(odd) {
    figure {
      margin: 0;
      padding-left: 20px;
      float: right;
      display: flex;
      flex-direction: column;
      img {
        padding-left: 0;
      }
    }

    img {
      float: right;
      margin-left: 12px;
    }
  }
  span:nth-of-type(even) {
    figure {
      margin: 0;
      padding-right: 20px;
      float: left;
      display: flex;
      flex-direction: column;
    }

    img {
      float: left;
      margin-right: 20px;
    }
  }
  .embed-youtube:nth-of-type(odd) {
    iframe {
      width: 480px;
      height: 270px;
    }
    padding-right: 20px;
    float: left;
  }
  .embed-youtube:nth-of-type(even) {
    iframe {
      width: 480px;
      height: 270px;
    }
    padding-left: 20px;
    float: right;
  }
`;
const StyledBox = styled(Box)`
  margin: 1rem 0px;
`;

export default function({
  Link,
  bodyText,
  cta,
  htmlSerializer,
  hubspotFormId,
  whitePaperDownload,
  whitePaperLabel,
  whitePaperGate,
  location,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isMedium = size === 'medium';
        const isSmall = size === 'small';

        return (
          <PublicationBodyContainer
            margin={{ bottom: 'large', left: isMedium || isSmall ? 'none' : 'xlarge' }}
            pad={isSmall && { horizontal: 'medium' }}
          >
            {Array.isArray(bodyText) ? (
              <RichText render={bodyText} htmlSerializer={htmlSerializer} />
            ) : (
              bodyText
            )}
            {hubspotFormId && whitePaperGate && <CRMForm hubspotFormId={hubspotFormId} />}
            {whitePaperGate && whitePaperDownload?.href && !hubspotFormId && (
              <SignupDownload
                size={size}
                buttonLabel={whitePaperLabel}
                href={whitePaperDownload?.href || cta?.url}
                location={location}
              />
            )}
            {cta.text && cta.url && !whitePaperGate && (
              <>
                {}
                <StyledBox>
                  <GatsbyButton
                    Link={Link}
                    alignSelf="start"
                    href={cta.url}
                    label={cta.text}
                    margin={{ top: 'small' }}
                    primary
                  />
                </StyledBox>
                {hubspotFormId ? (
                  <CRMForm hubspotFormId={hubspotFormId} />
                ) : whitePaperDownload?.href ? (
                  <SignupDownload
                    ctaHref={cta.url}
                    ctaLabel={cta.text}
                    size={size}
                    buttonLabel={whitePaperLabel}
                    href={whitePaperDownload?.href || cta?.url}
                    location={location}
                  />
                ) : null}
              </>
            )}
          </PublicationBodyContainer>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
