import React from 'react';
import { Box, Button, ResponsiveContext } from 'grommet';
import { FormClose } from 'grommet-icons';
import styled from 'styled-components';
import MarketCard from './MarketCard';

const StyledButton = styled(Button)`
  display: none;
  letter-spacing: 1px;
  line-height: 22px;
  @media screen and (min-width: 769px) {
    display: flex;
  }
`;

export default function({ Link, markets, onClose }) {
  const selectedMarket = typeof window !== 'undefined' && localStorage.getItem('selectedMarket');

  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const border =
          sizeResponsive === 'small'
            ? {
                size: '0',
              }
            : {
                color: 'neutral-4',
                size: '1px',
                style: 'solid',
                side: 'all',
              };
        const direction = sizeResponsive === 'small' ? 'column' : 'row';
        const wrap = sizeResponsive === 'small' ? null : true;
        const padding = sizeResponsive === 'small' ? 'none' : 'xsmall';

        return (
          <Box pad={padding} width="1044px" border={border}>
            <Box direction={direction} wrap={wrap} minHeight="500px">
              {markets &&
                markets.length &&
                markets.map((market) => {
                  const selected = market.node._meta.uid === selectedMarket;

                  return (
                    <MarketCard
                      key={market.node._meta.id}
                      Link={Link}
                      market={market.node}
                      selected={selected}
                    />
                  );
                })}
            </Box>
            <StyledButton
              alignSelf="end"
              icon={<FormClose plain="1" size="medium" />}
              label="close"
              margin="small"
              onClick={onClose}
              reverse
              small
            />
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
