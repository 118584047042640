import styled from 'styled-components';
import { Text } from 'grommet';

export default styled(Text)`
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 22px;
  text-transform: uppercase;

  @media only screen and (max-width: 600px) {
    font-size: 11px;
    letter-spacing: 1.18px;
    line-height: 22px;
  }
`;
