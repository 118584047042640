import React from 'react';
import { Box, Heading } from 'grommet';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';

const StyledHeading = styled(Heading)`
  line-height: 30px;
`;

export default function ContactCard({ Link, body, buttonText, buttonTo, size }) {
  return (
    <Box align="center" background="accent-2" pad="large">
      <StyledHeading
        level="6"
        margin={{ top: 'none', bottom: 'medium' }}
        size={size}
        textAlign="center"
      >
        {body}
      </StyledHeading>
      <GatsbyButton Link={Link} href={buttonTo} label={buttonText} />
    </Box>
  );
}
