/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Text, ResponsiveContext, Paragraph } from 'grommet';
import { RichText } from 'prismic-reactjs';
import { Picture } from '../Picture';

const RowReverseBox = styled(Box)`
  @media screen and (min-width: 769px) {
    flex-direction: row;

    #text-block {
      margin-left: 75px;
      margin-right: 0;
    }

    :nth-of-type(odd) {
      flex-direction: row-reverse;

      #text-block {
        margin-left: 0;
        margin-right: 75px;
      }
    }
  }
`;

const StyledBox = styled(Box)`
  position: relative;
`;

const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
`;

const SubStatContext = styled(Paragraph)`
  margin: 0;
  width: 100%;
  size: 20px;
  font-weight: bold;
  color: #272727;
`;

const Stat = styled(Heading)`
  color: #1082a4;
  margin: 0;

  @media screen and (min-width: 768px) {
    font-size: 63px;
    letter-spacing: -1.5px;
  }
`;

const SubStatText = styled(Text)`
  size: 18px;
  font-style: italic;
  color: #4b4b4b;
`;

const RichTextContainer = styled(Box)`
  ul {
    li {
      letter-spacing: 0.5px;
      margin: 5px 0;
    }
  }
`;

export default function HowWeWorkCard({ cardData, htmlSerializer }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const headingMargin =
          size === 'small' ? { top: '20px', bottom: 'none' } : { top: 'none', bottom: 'none' };
        return (
          <RowReverseBox justify="between" width="1440px" pad={{ vertical: 'large' }} margin="auto">
            <Box basis="49%" align="center">
              <StyledPicture src={cardData.image.url} alt={cardData.image.alt || ''} />
            </Box>
            <Box basis="49%" id="text-block">
              <Heading level="3" margin={headingMargin}>
                {cardData.heading}
              </Heading>
              <RichTextContainer>
                <RichText render={cardData.text} htmlSerializer={htmlSerializer} />
              </RichTextContainer>
              <StyledBox pad="medium" border="all" align="start" justify="center" width="250px">
                <Stat size={size} level={2}>
                  {cardData.stat}
                </Stat>
                <SubStatContext>{cardData.statContext}</SubStatContext>
                <SubStatText>{cardData.statSub}</SubStatText>
              </StyledBox>
            </Box>
          </RowReverseBox>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
