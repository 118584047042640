import React from 'react';
import { Box, Heading } from 'grommet';
import EventsCarousel from '../EventsCarousel';
import EventsCard from '../EventsCard';
import 'pure-react-carousel/dist/react-carousel.es.css';

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'medium', vertical: 'large' };
    case 'medium':
      return 'large';
    default:
      return 'xlarge';
  }
}

export default function({ Link, size, header, events }) {
  const isSmall = size === 'small';
  const isMedium = size === 'medium';

  return (
    <Box background="accent-2" direction="column" pad={mainPadding(size)}>
      <Box
        width="1440px"
        margin="auto"
        direction={isSmall || isMedium ? 'column' : 'row'}
        gap="medium"
      >
        <Box basis={isSmall || isMedium ? 'full' : '1/4'} flex>
          <Heading margin={{ top: 'none', bottom: 'medium' }} size={size} level="2">
            {header}
          </Heading>
        </Box>
        {isSmall ? (
          <EventsCarousel size={size} allEvents={events} />
        ) : (
          <Box gap="medium" direction="row" basis={isMedium ? 'full' : '3/4'}>
            {events.map((elem) => (
              <Box key={elem.id} basis="1/3">
                <EventsCard
                  Link={Link}
                  eventDate={elem.eventDate}
                  eventDescription={elem.eventDescription}
                  eventLocation={elem.eventLocation}
                  eventTitle={elem.eventTitle}
                  eventUrl={elem.eventUrl}
                  size={size}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
