import React from 'react';
import styled from 'styled-components';
import { Box, Image, Text, Heading } from 'grommet';
import { RichText } from 'prismic-reactjs';
import GatsbyButton from '../GatsbyButton';

const CardTitleText = styled(Heading)`
  margin: 0px;
  padding: 30px 0px 0px 0px;
`;

const CardBodyText = styled(Text)`
  color: #272727;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const StyledImage = styled(Image)`
  flex: none;
`;

export default function({ Link, image, title, blurb, size, ctaText, ctaUrl, htmlSerializer }) {
  const calcBasis = size === 'small' ? '1' : '1/3';

  return (
    <Box basis={calcBasis} margin="6px" pad={{ horizontal: '6px', vertical: '6px' }}>
      <Box>
        <StyledImage src={image.url} alt={image.alt || ''} fit="cover" />
      </Box>
      <CardTitleText size={size} level="3">
        {title}
      </CardTitleText>
      <CardBodyText size={size}>
        <RichText render={blurb} htmlSerializer={htmlSerializer} />
      </CardBodyText>
      {ctaText ? (
        <Box>
          <ButtonContainer>
            <GatsbyButton Link={Link} primary label={ctaText} href={!ctaUrl ? '#' : ctaUrl} />
          </ButtonContainer>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
}
