import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import AnchorArrow from '../AnchorArrow';
import ContactCard from '../ContactCard';
import EyebrowBox from '../../typography/EyebrowBox';
import GatsbyAnchor from '../GatsbyAnchor';
import GatsbyButton from '../GatsbyButton';
import Keywords from '../Keywords';
import PersonCard from '../PersonCard';

const SegmentContainer = styled(Box)`
  max-width: 1440px;
  margin: 0 auto;
`;

const SegmentCtaContainer = styled(Box)`
  margin: 1rem 0;
  position: relative;
`;

const SegmentCtaButton = styled(GatsbyButton)`
  z-index: 1;
`;

const CaseStudyBlurb = styled(Paragraph)`
  max-width: 100%;
`;

function segmentContatinerPadding(size) {
  switch (size) {
    case 'small':
      return null;
    case 'medium':
      return 'large';
    default:
      return { horizontal: 'xlarge', vertical: '70px' };
  }
}

function sanitizeId(Id) {
  return Id.replace(/[/,]/g, '');
}

export default function({
  Link,
  background,
  contactButtonText,
  contactButtonTo,
  contactUsImage,
  contactUsPerson,
  contactUsPhone,
  contactUsTagline,
  ctaTitle,
  ctaUrl,
  htmlSerializer,
  link,
  title,
  text,
  proofs,
}) {
  const [proof, setProof] = useState(proofs && proofs.length > 0 && proofs[0].proof);

  const proofUrl = proof && `/library/${proof._meta.uid}`;
  const callCta = ctaUrl && ctaUrl._meta?.type;
  const ctaHref = ctaUrl && ctaUrl.url;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isMedium = size === 'medium';
        const isSmall = size === 'small';

        return (
          <Box background={background} pad={segmentContatinerPadding(size)}>
            <SegmentContainer
              align="start"
              direction="row-responsive"
              fill="horizontal"
              gap="large"
              id={sanitizeId(link.replace('#', ''))}
              style={isSmall ? { display: 'block' } : null}
            >
              <Box basis={isMedium ? '1/2' : '2/3'} pad={isSmall ? 'medium' : null}>
                <Heading size={size} level="2" margin="none" color="dark-4">
                  {title}
                </Heading>
                <RichText render={text} htmlSerializer={htmlSerializer} />
                {ctaTitle && ctaUrl && (
                  <SegmentCtaContainer direction="column">
                    <SegmentCtaButton
                      Link={Link}
                      label={ctaTitle[0].text}
                      href={ctaHref || callCta}
                      primary
                      alignSelf="start"
                    />
                  </SegmentCtaContainer>
                )}
                {proof && (
                  <Box
                    align="start"
                    direction={isMedium ? 'column' : 'row-responsive'}
                    fill="horizontal"
                    margin={{ top: 'medium' }}
                    flex={false}
                    style={isSmall ? { display: 'block' } : null}
                  >
                    {proof.image && (
                      <img
                        alt={proof.image.alt || ''}
                        fit="contain"
                        src={proof.image.thumbnail.url}
                        width={isMedium || isSmall ? '100%' : '50%'}
                      />
                    )}
                    <Box
                      width={isMedium || isSmall ? '100%' : '50%'}
                      pad={isMedium || isSmall ? { top: 'medium' } : { horizontal: 'medium' }}
                      align="start"
                    >
                      {proof.type && (
                        <EyebrowBox
                          Link={Link}
                          as={GatsbyAnchor}
                          href={`/library?type=${proof.type}`}
                          margin="none"
                        >
                          {proof.type}
                        </EyebrowBox>
                      )}
                      {proof.title && (
                        <Heading
                          size={size}
                          level="4"
                          margin={{ vertical: 'small' }}
                          color="dark-4"
                        >
                          {proof.title}
                        </Heading>
                      )}
                      {proof.summary && (
                        <CaseStudyBlurb size={size} margin={{ vertical: 'xsmall' }}>
                          {proof.summary[0].text}…
                        </CaseStudyBlurb>
                      )}
                      {proofUrl && (
                        <GatsbyAnchor Link={Link} href={proofUrl} size={size}>
                          Read more <AnchorArrow />
                        </GatsbyAnchor>
                      )}
                    </Box>
                  </Box>
                )}
                {!isSmall && proofs && proofs.length > 0 && (
                  <Box display="block" pad={{ top: 'large' }}>
                    <Keywords proofs={proofs} activeProof={proof} setProof={setProof} />
                  </Box>
                )}
              </Box>
              <Box basis={isMedium ? '1/2' : '1/3'} flex={false}>
                {contactUsImage ? (
                  <PersonCard
                    contactQuote={contactUsTagline}
                    contactPhoto={contactUsImage}
                    contactName={contactUsPerson}
                    contactPhone={contactUsPhone}
                    contactButtonText={contactButtonText}
                    contactButtonTo={contactButtonTo}
                    size={size}
                  />
                ) : (
                  <ContactCard
                    Link={Link}
                    body={contactUsTagline}
                    buttonText={contactButtonText}
                    buttonTo={contactButtonTo}
                    size={size}
                  />
                )}
              </Box>
            </SegmentContainer>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
