import React from 'react';
import { Box } from 'grommet';
import SignUpForm from '../../components/SignUp/SignUpForm';

export default function CRMForm({ hubspotFormId }) {
  return (
    <Box pad="large" background="dark-1">
      <SignUpForm hubspotFormId={hubspotFormId} />
    </Box>
  );
}
