/**
 * deg2rad
 * @param   {number} deg
 * @returns {number} degrees to radians
 */
export function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

/**
 * coordDistance
 * @param   {number}  lat1
 * @param   {number}  lon1
 * @param   {number}  lat2
 * @param   {number}  lon2
 * @returns {number}  miles between coordiantes
 */
export function coordDistance(lat1, lon1, lat2, lon2) {
  const radius = 3959; // Earth radius in miles
  const latDiff = deg2rad(lat2 - lat1);
  const lonDiff = deg2rad(lon2 - lon1);
  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(lonDiff / 2) *
      Math.sin(lonDiff / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return +(radius * c).toFixed(2);
}

/**
 * mapDistance
 * @param   {object}  { lat, lng, ... }
 * @param   {array}   [{ lat, lng, ... },...]
 * @returns {array}   sorted location objects with dist
 */
export function mapDistance(origin, locations) {
  return locations
    .map((location) => ({
      ...location,
      dist: coordDistance(origin.lat, origin.lng, location.lat, location.lng),
    }))
    .sort((a, b) => {
      if (a.dist > b.dist) return 1;
      if (a.dist < b.dist) return -1;
      return 0;
    });
}
