import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Cta, Stats, Picture } from '../../index';
import { mainGap, mainPadding } from '../../utils/layout';

export default function CaseStudyBlock({ image, stats, ...rest }) {
  const statsCards = stats.map((s) => <Stats stats={s} key={Math.random()} />);

  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        return (
          <Box
            as="section"
            background={{ color: 'dark-1' }}
            pad={mainPadding(sizeResponsive)}
            justify="center"
          >
            <Box
              gap={mainGap(sizeResponsive)}
              direction="row-responsive"
              width="1440px"
              margin="auto"
            >
              <Box basis="49%" alignSelf="center">
                <Cta {...rest} />
              </Box>
              <Box basis="49%">
                <Box background={{ color: 'white' }} pad="small">
                  <Picture src={image.url} alt={image.alt || ''} width="100%" />
                  <Box direction="row" justify="between">
                    {statsCards}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
