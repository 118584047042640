/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Prismic from 'prismic-javascript';
import styled from 'styled-components';
import { Button, Box, Heading } from 'grommet';
import CurrentsCard from './CurrentsCard';
import LibraryCard from '../LibraryBlock/LibraryCard';
import LibraryFilter from '../../components/LibraryFilter';
import { createQueryString, parseQueryString } from '../../utils/url';

const apiEndpoint = process.env.GATSBY_PRISMIC_ENDPOINT;
const accessToken = process.env.PRISMIC_ACCESS_TOKEN;
const api = Prismic.client(apiEndpoint, { accessToken });

const LibraryResultsList = styled(Box)`
  &:after {
    content: '';
    flex: 1 1;
  }
`;

async function queryPrismic({ market, region, service, term, type }, page = 1) {
  const filters = [Prismic.Predicates.at('document.type', 'publication')];

  if (market) {
    filters.push(Prismic.Predicates.at('my.publication.related_markets.market', market));
  }

  if (region) {
    filters.push(Prismic.Predicates.at('my.publication.related_regions.region', region));
  }

  if (service) {
    filters.push(
      Prismic.Predicates.at('my.publication.related_service_areas.service_area', service),
    );
  }

  if (term) {
    filters.push(Prismic.Predicates.fulltext('document', term));
  }

  if (type) {
    filters.push(Prismic.Predicates.at('my.publication.type', type));
  }

  return api.query(filters, {
    orderings:
      '[my.publication.date desc, my.publication.event_start_date desc, document.last_publication_date desc]',
    page,
    pageSize: 20,
  });
}

function getOptionId(key, filters, data) {
  return filters[key] && data.reduce((a, b) => (b.value === filters[key] ? b.id : a), '');
}

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { vertical: 'large' };
    case 'medium':
      return 'large';
    default:
      return { horizontal: 'xlarge', vertical: 'large' };
  }
}

export default function({ Link, size, markets, regions, services, types }) {
  const isSmall = size === 'small';

  const query =
    typeof window !== `undefined` &&
    window.location.search &&
    parseQueryString(window.location.search);

  const [filters, setFilters] = useState(query);
  const [loaded, setLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);

  async function getResults() {
    const service = getOptionId('service', filters, services);
    const market = getOptionId('market', filters, markets);

    const { results: docs, next_page: next } = await queryPrismic(
      { ...filters, service, market },
      page,
    );

    const pubs = docs.map(({ id, uid, data }) => {
      const excerpt =
        (data.summary && data.summary.length && data.summary[0].text) ||
        (data.text && data.text[0].text) ||
        '';

      return {
        id,
        category: data.type,
        title: data.title,
        to: `/library/${uid}`,
        image: data.image.thumbnail,
        excerpt: excerpt.length > 140 ? `${excerpt.substring(0, 140)}…` : excerpt,
        linkText: 'Read more',
      };
    });

    setResults(page > 1 ? [...results, ...pubs] : pubs);

    if (!loaded) setLoaded(true);

    setHasMore(!!next);
  }

  useEffect(() => {
    if (loaded && page) getResults();
  }, [page]);

  useEffect(() => {
    if (filters) {
      const url = `?${createQueryString(filters)}`;

      if (url !== window.location.search || !loaded) {
        if (page > 1) {
          setPage(1);
        } else {
          getResults();
        }

        if (loaded) window.history.pushState(null, null, url);
      }
    }
  }, [filters]);

  return (
    <>
      <LibraryFilter
        onChange={(value) => setFilters(value)}
        markets={markets}
        regions={regions}
        services={services}
        types={types}
        values={query}
      />
      <Box as="section" pad={mainPadding(size)}>
        <LibraryResultsList
          direction="row-responsive"
          justify="between"
          width="1440px"
          margin="auto"
          align="start"
          wrap={!isSmall}
        >
          {results &&
            results.map((item, index) => (
              <>
                <LibraryCard
                  Link={Link}
                  key={item.id}
                  library={item}
                  margin={{ bottom: 'large' }}
                />
                {index === 1 && (
                  <CurrentsCard
                    Link={Link}
                    currentsBody="Peruse our newsletters and pick the ones that pique your interest!"
                    currentsHeading="Bring POWER to Your Inbox"
                    key="NewsletterCard"
                    tagHref="/newsletters"
                    tagLabel="View all"
                    type=""
                  />
                )}
              </>
            ))}
          {!results.length && loaded && (
            <Heading level="3" maring="none" color="dark-4">
              Sorry. No results were found.
            </Heading>
          )}
        </LibraryResultsList>
        {hasMore && (
          <Button alignSelf="center" onClick={() => setPage(page + 1)} label="Load more" />
        )}
      </Box>
    </>
  );
}
