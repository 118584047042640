import React from 'react';
import { Box, ResponsiveContext, Heading, Layer, Button, Paragraph } from 'grommet';
import { Close } from 'grommet-icons';
import { RichText } from 'prismic-reactjs';
import GatsbyButton from '../GatsbyButton';
import SignUpForm from '../SignUp/SignUpForm';

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'medium', vertical: 'xlarge' };
    case 'medium':
      return 'large';
    default:
      return { horizontal: 'xlarge', vertical: 'large' };
  }
}

export default function ButtonBanner({
  heading,
  href,
  Link,
  button,
  hubspotFormId,
  aboutPower,
  htmlSerializer,
}) {
  const [show, setShow] = React.useState();
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const align = sizeResponsive === 'small' ? 'center' : 'end';
        const textAlign = sizeResponsive === 'small' ? 'center' : 'start';
        const mobileGap = sizeResponsive === 'small' ? 'medium' : '';
        return (
          <Box background="accent-2">
            <Box
              direction="row-responsive"
              alignSelf="center"
              width="1440px"
              pad={mainPadding(sizeResponsive)}
              gap={mobileGap}
            >
              <Box responsive basis="2/3">
                <Heading
                  size={sizeResponsive}
                  margin={{ vertical: 'small' }}
                  level={5}
                  label={heading}
                  textAlign={textAlign}
                  align="start"
                >
                  {' '}
                  {heading}{' '}
                </Heading>
                {Array.isArray(aboutPower) ? (
                  <RichText render={aboutPower} htmlSerializer={htmlSerializer} />
                ) : (
                  <Paragraph>{aboutPower}</Paragraph>
                )}
              </Box>
              <Box align={align} responsive basis="1/3">
                {!hubspotFormId && href && Link && (
                  <GatsbyButton
                    Link={Link}
                    href={href || '/'}
                    default
                    dark
                    label={button}
                    onClick={() => setShow(true)}
                  />
                )}
                {hubspotFormId && (
                  <Button label={button} default dark onClick={() => setShow(true)} />
                )}
                {hubspotFormId && show && (
                  <Layer
                    margin={{ top: '75px' }}
                    responsive
                    overlay="rgba(230,61,47,0.9)"
                    zIndex="100"
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                  >
                    <Box pad="large" background="dark-1">
                      <Button
                        alignSelf="end"
                        onClick={() => setShow(false)}
                        icon={<Close color="light-1" />}
                      />
                      <SignUpForm hubspotFormId={hubspotFormId} />
                    </Box>
                  </Layer>
                )}
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
