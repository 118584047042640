import React, { useState } from 'react';
import { DropButton, ResponsiveContext, Box } from 'grommet';
import { Down, Up } from 'grommet-icons';
import styled from 'styled-components';
import MarketSelectorMenu from './MarketSelectorMenu';

const MenuBox = styled(Box)`
  min-height: 500px;
`;

export default function({ isHomePage, Link, markets, ...rest }) {
  const StyledDropButton = styled(DropButton)`
    text-transform: none;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    padding: 26px 38px;
    width: 100%;
    ${isHomePage && 'border: 1px solid #4ECBEF;'}

    div {
      justify-content: space-between;
    }

    @media screen and (min-width: 769px) {
      width: unset;
    }
  `;
  const [open, setOpen] = useState(false);
  const selectedMarket = typeof window !== 'undefined' && localStorage.getItem('selectedMarket');
  let label = 'Choose your industry';
  if (selectedMarket) {
    const market = markets.filter((item) => {
      return item.node._meta.uid === selectedMarket;
    });
    label = market[0].node.name;
  }

  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const elevation = sizeResponsive === 'small' ? 'none' : 'small';
        const dropAlignment =
          sizeResponsive === 'small'
            ? { top: 'bottom', left: 'left' }
            : { top: 'bottom', left: 'left' };
        let buttonColor;
        if (open && isHomePage) {
          buttonColor = 'white';
        } else {
          buttonColor = isHomePage ? 'dark-1' : 'neutral-4';
        }

        return (
          <>
            <StyledDropButton
              style={
                isHomePage
                  ? { border: '1px solid #4ecbef' }
                  : { fontSize: '18px', lineHeight: '24px', color: '#0D0E12' }
              }
              icon={open ? <Up /> : <Down />}
              color={buttonColor}
              label={label}
              dropAlign={dropAlignment}
              dropContent={
                <MenuBox>
                  <MarketSelectorMenu
                    Link={Link}
                    onClose={() => setOpen(false)}
                    markets={markets}
                  />
                </MenuBox>
              }
              dropProps={{
                elevation,
                stretch: false,
                overflow: 'hidden',
                restrictFocus: true,
                responsive: false,
              }}
              onClick={() => {
                setOpen(!open);
                // Send GTM event when clicked, determine if its on the homepage or not
                if (isHomePage) {
                  window.dataLayer.push({
                    event: 'GAEvent',
                    eventCategory: 'Homepage Navigation',
                    eventAction: 'Body',
                    eventLabel: 'Choose your industry',
                  });
                } else {
                  window.dataLayer.push({
                    event: 'GAEvent',
                    eventCategory: 'Homepage Navigation',
                    eventAction: 'Top Nav',
                    eventLabel: 'Choose your industry',
                  });
                }
              }}
              onClose={() => setOpen(false)}
              open={open}
              primary
              reverse
              {...rest}
            />
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
