import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import SignUpForm from '../../components/SignUp/SignUpForm';
import { mainPadding, mainGap } from '../../utils/layout';

export default function PowerCurrentsBlock({ bodyText, hubspotFormId, htmlSerializer }) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        return (
          <Box width="1440px" alignSelf="center" pad={mainPadding(sizeResponsive)} responsive>
            <Box direction="row-responsive" gap={mainGap(sizeResponsive)}>
              <Box basis="1/2" direction="column">
                <RichText render={bodyText} htmlSerializer={htmlSerializer} />
              </Box>
              <Box basis="1/2">
                <SignUpForm hubspotFormId={hubspotFormId} />
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
