import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Text, Anchor, ThemeContext } from 'grommet';
import ExternalLinkIcon from '../ExternalLinkIcon';
import GatsbyAnchor from '../GatsbyAnchor';

const LinkSection = styled(Box)`
  line-height: 30px;

  &:first-child h5 {
    margin-top: 0;
  }
`;

function mapLinks({ Link, item }) {
  const { type, text, href } = item;

  switch (type) {
    case 'text':
      return <Text color="dark-1">{text}</Text>;
    case 'link':
      return <GatsbyAnchor Link={Link} href={href} label={text} />;
    case 'external link':
      return (
        <Anchor
          href={href}
          icon={<ExternalLinkIcon />}
          label={text}
          rel="noopener noreferrer"
          target="_blank"
        />
      );
    default:
      return null;
  }
}

function mapLinkSection({ Link, heading, links }) {
  return (
    <LinkSection flex={false}>
      <ThemeContext.Extend
        value={{
          heading: { weight: 700 },
          anchor: {
            extend: () => `
            span { text-decoration: underline; }
            &:hover span  { text-decoration: none; }
          `,
          },
        }}
      >
        <Heading level="5" color="dark-1" margin={{ top: 'medium', bottom: 'small' }} size="small">
          {heading}
        </Heading>
        {links && links.map((item) => mapLinks({ Link, item }))}
      </ThemeContext.Extend>
    </LinkSection>
  );
}

export default function({ Link, sidebar, externalLinks }) {
  return (
    <>
      {sidebar && sidebar.length && (
        <Box flex={false} border="all" pad="medium" margin={{ bottom: 'large' }}>
          {sidebar.map(({ heading, links }) => mapLinkSection({ Link, heading, links }))}
        </Box>
      )}
      {externalLinks && externalLinks.length && (
        <Box flex={false} border="all" pad="medium">
          {externalLinks.map(({ heading, links }) => mapLinkSection({ Link, heading, links }))}
        </Box>
      )}
    </>
  );
}
