import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Image, Paragraph, ResponsiveContext } from 'grommet';
import AnchorArrowIcon from '../../components/AnchorArrow/AnchorArrowIcon';
import EyebrowBox from '../../typography/EyebrowBox';
import GatsbyAnchor from '../../components/GatsbyAnchor';

const StyledImage = styled(Image)`
  flex: none;
`;

export default function LibraryCard({ Link, library, ...rest }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';

        return (
          <Box
            align="start"
            direction={isSmall ? 'row' : 'column'}
            width={isSmall ? '100%' : '33.333%'}
            gap={isSmall ? 'small' : null}
            pad={isSmall ? { horizontal: 'large', vertical: 'small' } : 'small'}
            {...rest}
          >
            <StyledImage
              alt={library.image.alt || ''}
              fit="contain"
              src={library.image.url || ''}
              width={isSmall ? '33.333%' : '100%'}
              margin={{ bottom: 'medium' }}
            />
            <Box width={isSmall ? '66.667%' : '100%'} flex={false}>
              {library.category && (
                <EyebrowBox
                  Link={Link}
                  as={GatsbyAnchor}
                  href={`/library?type=${library.category}`}
                >
                  {library.category}
                </EyebrowBox>
              )}
              <Heading level="4" margin={{ vertical: 'xsmall' }} size={size}>
                {library.title}
              </Heading>
              {!isSmall && <Paragraph margin={{ top: 'none' }}>{library.excerpt}</Paragraph>}
              <GatsbyAnchor Link={Link} href={library.to} alignSelf="start">
                {library.linkText ? library.linkText : 'Read more'}
                <AnchorArrowIcon style={{ marginLeft: '0.5rem' }} />
              </GatsbyAnchor>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
