import React from 'react';
import { Anchor } from 'grommet';
import { isEmail, isExternal, forceSlashes } from '../../utils/url';

export default function({ Link, href, ...rest }) {
  if (!href) return null;

  if (isEmail(href)) return <Anchor as="a" href={href} {...rest} />;

  if (isExternal(href))
    return <Anchor as="a" href={href} target="_blank" rel="noopener noreferrer" {...rest} />;

  const url = forceSlashes(href);
  return <Anchor as={Link || 'a'} to={!Link ? null : url} href={!Link ? url : null} {...rest} />;
}
