import React from 'react';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import { EyebrowText } from '../../index';
import GatsbyButton from '../GatsbyButton';

export default function Cta({
  Link,
  bodyProps,
  boxProps,
  ctaButtonProps,
  eyebrowProps,
  titleProps,
  htmlSerializer,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        return (
          <Box {...boxProps}>
            <EyebrowText {...eyebrowProps}>{eyebrowProps.text || ''}</EyebrowText>
            <Heading
              margin={{ top: 'medium', bottom: '0px' }}
              level="2"
              size={sizeResponsive}
              {...titleProps}
            >
              {titleProps.text || ''}
            </Heading>
            {Array.isArray(bodyProps.text) ? (
              <RichText render={bodyProps.text} htmlSerializer={htmlSerializer} />
            ) : (
              <Paragraph size={sizeResponsive} fill {...bodyProps}>
                {bodyProps.text || ''}
              </Paragraph>
            )}
            {ctaButtonProps.to && ctaButtonProps.text ? (
              <div>
                <GatsbyButton
                  Link={Link}
                  primary
                  label={ctaButtonProps.text}
                  href={ctaButtonProps.to || ''}
                />
              </div>
            ) : (
              ''
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
