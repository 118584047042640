import React from 'react';
import { Box } from 'grommet';

const setShareLink = (name, title) => {
  const url = window.location.href;
  let shareLink;

  switch (name) {
    case 'X':
      shareLink = `https://twitter.com/intent/tweet/?text=${title}&url=${url}`;
      break;
    case 'Facebook':
      shareLink = `https://www.facebook.com/share.php?u=${url}&title=${title}`;
      break;
    case 'Linkedin':
      shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`;
      break;
    case 'Mail':
      shareLink = `mailto:?subject=${title}&body=${url}`;
      break;
    default:
      shareLink = null;
  }

  return encodeURI(shareLink);
};

export default function SocialCard({ name, icon, background, title }) {
  const shareLink = setShareLink(name, title);

  return (
    <a id={name} href={shareLink} target="_blank" rel="noopener noreferrer">
      <Box
        height="xxsmall"
        width="xxsmall"
        background={background}
        justify="center"
        align="center"
        margin={{ right: 'xsmall' }}
        round="full"
      >
        {icon}
      </Box>
    </a>
  );
}
