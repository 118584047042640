import React from 'react';
import { Box, Button, Image, Paragraph } from 'grommet';
import styled from 'styled-components';

const QuoteText = styled(Paragraph)`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: italic;
  letter-spacing: 0.13px;
  line-height: 36px;
  text-align: center;
  max-width: 100%;
`;

const NameText = styled(Paragraph)`
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 1.46px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
`;

const ContactButton = styled(Button)`
  text-align: center;
  transform: translateY(-50%);
  background-color: black;
  min-width: 200px;
`;
const SpanDivider = styled.span`
  width: 100%;
  border-top: 1px solid #faedeb;
`;

export default function PersonCard({
  contactButtonText,
  contactButtonTo,
  contactPhone,
  contactName,
  contactPhoto,
  contactQuote,
  size,
}) {
  return (
    <Box
      background="accent-2"
      pad={{ horizontal: 'medium', top: 'large', bottom: 'medium' }}
      flex={false}
    >
      <svg width="100%" height="52" viewBox="0 0 52 52">
        <line x1="-800" y1="40" x2="852" y2="40" stroke="#fff" />
        <path
          d="M50.4998 50.9999H50.9998V50.4999V29.3999V28.8999H50.4998H41.9998V26.2999C41.9998 24.5159 42.0988 22.7389 42.2958 21.2659V21.2619C42.4868 19.7349 42.9628 18.3949 43.5398 17.2379C44.2018 16.1069 45.1518 15.0619 46.2968 14.2019C47.3398 13.4449 48.7858 12.7649 50.5578 12.1739L50.8998 12.0599V11.6999V1.69994V1.05094L50.2728 1.21594C44.4878 2.73894 39.6918 5.58794 36.2148 9.78094C32.6268 14.0879 30.7998 19.7079 30.7998 26.3999V50.9999H50.4998ZM20.5998 50.9999H21.0998V50.4999V29.3999V28.8999H20.5998H12.0998V26.2999C12.0998 24.5159 12.1988 22.7389 12.3958 21.2659V21.2619C12.5868 19.7349 13.0628 18.3949 13.6398 17.2379C14.3098 16.0929 15.1618 15.0579 16.3848 14.2109L16.3938 14.2039C17.4368 13.4459 18.8838 12.7659 20.6578 12.1739L20.9998 12.0599V11.6999V1.69994V1.05094L20.3728 1.21594C14.5888 2.73894 9.79179 5.58794 6.31479 9.77994C2.72679 14.0869 0.899786 19.7069 0.899786 26.3999V50.9999H20.5998Z"
          fill="#cb361d"
          stroke="#fff"
          strokeWidth="1"
        />
      </svg>
      <QuoteText textAlign="center" margin={{ vertical: 'xlarge' }}>
        {contactQuote}
      </QuoteText>
      <SpanDivider />
      <Box
        align="center"
        flex={false}
        pad={{
          vertical: 'large',
          horizontal: 'small',
          bottom: size === 'small' ? 'xlarge' : 'large',
        }}
      >
        <Box width="180px" height="180px" overflow="hidden" round="full" flex={false}>
          <Image
            alt={contactPhoto.alt || ''}
            fit="cover"
            height="100%"
            src={contactPhoto.url}
            width="100%"
          />
        </Box>
        <NameText margin={{ top: 'medium', bottom: 'none' }}>{contactName}</NameText>
        <Paragraph margin={{ top: 'xsmall', bottom: 'none' }}>{contactPhone}</Paragraph>
      </Box>
      <ContactButton
        alignSelf="center"
        background="accent-1"
        color="dark-1"
        href={contactButtonTo}
        label={contactButtonText}
      />
    </Box>
  );
}
