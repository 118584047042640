import React, { useRef, useState } from 'react';
import { Box, Button, Drop, ResponsiveContext } from 'grommet';
import { Close, Menu } from 'grommet-icons';
import styled from 'styled-components';
import Logo from '../../components/Logo';
import GatsbyAnchor from '../../components/GatsbyAnchor';
import HeaderNav from './HeaderNav';

const HeaderContainer = styled.header`
  border-bottom: 1px solid #000;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.36);
  position: sticky;
  top: 0;
  z-index: 50;
`;

const HeaderDrop = styled(Drop)`
  height: 100%;
  max-height: 100% !important;
  box-shadow: none;
`;

export default function({ Link, markets, ...rest }) {
  const headerRef = useRef(null);
  const [show, setShow] = useState(false);

  return (
    <HeaderContainer id="header">
      <Box
        align="center"
        background="white"
        direction="row"
        justify="between"
        pad={{ horizontal: 'medium' }}
        ref={headerRef}
        {...rest}
      >
        <GatsbyAnchor
          Link={Link}
          href="/"
          onClick={() => localStorage.removeItem('selectedMarket')}
        >
          <Box pad={{ vertical: 'small', right: 'medium' }}>
            <ResponsiveContext.Consumer>
              {(size) => {
                const isSmall = size === 'small';
                const attrs = {
                  width: isSmall ? 155 : 175,
                  height: isSmall ? 47 : 53,
                };

                return <Logo {...attrs} />;
              }}
            </ResponsiveContext.Consumer>
          </Box>
        </GatsbyAnchor>
        <ResponsiveContext.Consumer>
          {(size) =>
            ['small', 'medium'].includes(size) ? (
              <>
                <Button
                  icon={show ? <Close size="26px" /> : <Menu />}
                  plain
                  onClick={() => setShow(!show)}
                />
                {show && (
                  <HeaderDrop
                    align={{ top: 'bottom', right: 'right' }}
                    onClickOutside={() => setShow(false)}
                    onEsc={() => setShow(false)}
                    target={headerRef.current}
                    plain
                  >
                    <HeaderNav
                      Link={Link}
                      alignContent="center"
                      background="dark-1"
                      gap="medium"
                      justify="start"
                      fill="vertical"
                      align="center"
                      color="#fff"
                      markets={markets}
                      mobile
                    />
                  </HeaderDrop>
                )}
              </>
            ) : (
              <HeaderNav
                color="#000"
                Link={Link}
                markets={markets}
                align="center"
                direction="row"
                headerRef={headerRef}
                gap="35px"
                collapse
              />
            )
          }
        </ResponsiveContext.Consumer>
      </Box>
    </HeaderContainer>
  );
}
