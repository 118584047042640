/**
 * HTML Serializer
 * Modifies the way that a RichText field is rendered from Prismic.
 * https://prismic.io/docs/reactjs/getting-started/prismic-gatsby
 * https://prismic.io/docs/reactjs/beyond-the-api/html-serializer#5_1-using-the-serializer-function
 */

import React from 'react';
import { Link as PrismicLink } from 'prismic-reactjs';
import { Link } from 'gatsby';
import { Anchor, Heading, ResponsiveContext, Text } from '../ui';
import { linkResolver } from './linkResolver';

function serializeHyperlink(element, content) {
  let url;
  if (element.data.link_type === 'Web') url = element.data.url;
  else if (element.data.link_type === 'Document')
    url = PrismicLink.url(element.data, linkResolver).replace('https://', '/');
  else url = '/';

  let result = '';

  if (element.data.link_type === 'Document' || url.startsWith('/')) {
    result = (
      <Anchor as={Link} to={url} key={`anchor-doc-${Math.random()}`}>
        {content}
      </Anchor>
    );
  } else {
    const target = element.data.target ? { target: element.data.target, rel: 'noopener' } : {};

    result = (
      <Anchor href={url} {...target} key={`anchor-${Math.random()}`}>
        {content}
      </Anchor>
    );
  }

  return result;
}

function serializeImage(element) {
  const wrapperClassList = [element.label || '', 'block-img'];

  let result =
    element.alt !== null || undefined ? (
      <figure>
        <img
          className="captioned"
          src={element.url}
          alt={element.alt || ''}
          copyright={element.copyright || ''}
        />
        <figcaption>
          <p>{element.alt}</p>
        </figcaption>
      </figure>
    ) : (
      <img src={element.url} alt={element.alt || ''} copyright={element.copyright || ''} />
    );

  if (element.linkTo) {
    const url = PrismicLink.url(element.linkTo, linkResolver);

    if (element.linkTo.link_type === 'Document') {
      result = (
        <Anchor as={Link} to={url} key={`image-doc-${Math.random()}`}>
          {result}
        </Anchor>
      );
    } else {
      const target = element.linkTo.target
        ? { target: element.linkTo.target, rel: 'noopener' }
        : {};

      result = (
        <Anchor href={url} {...target} key={`image-${Math.random()}`}>
          {result}
        </Anchor>
      );
    }
  }

  result = (
    <Text className={wrapperClassList.join(' ')} key={`text-${Math.random()}`}>
      {result}
    </Text>
  );

  return result;
}

export default function(type, element, content, children) {
  switch (type) {
    case 'heading1':
      return (
        <ResponsiveContext.Consumer key={`heading1-${Math.random()}`}>
          {(size) => {
            return (
              <Heading level="1" size={size}>
                {children}
              </Heading>
            );
          }}
        </ResponsiveContext.Consumer>
      );

    case 'heading2':
      return (
        <ResponsiveContext.Consumer key={`heading2-${Math.random()}`}>
          {(size) => {
            return (
              <Heading level="2" size={size}>
                {children}
              </Heading>
            );
          }}
        </ResponsiveContext.Consumer>
      );

    case 'heading3':
      return (
        <ResponsiveContext.Consumer key={`heading3-${Math.random()}`}>
          {(size) => {
            return (
              <Heading level="3" size={size}>
                {children}
              </Heading>
            );
          }}
        </ResponsiveContext.Consumer>
      );

    case 'heading4':
      return (
        <ResponsiveContext.Consumer key={`heading4-${Math.random()}`}>
          {(size) => {
            return (
              <Heading level="4" size={size}>
                {children}
              </Heading>
            );
          }}
        </ResponsiveContext.Consumer>
      );

    case 'heading5':
      return (
        <ResponsiveContext.Consumer key={`heading5-${Math.random()}`}>
          {(size) => {
            return (
              <Heading level="5" size={size}>
                {children}
              </Heading>
            );
          }}
        </ResponsiveContext.Consumer>
      );

    case 'heading6':
      return (
        <ResponsiveContext.Consumer key={`heading6-${Math.random()}`}>
          {(size) => {
            return (
              <Heading level="6" size={size}>
                {children}
              </Heading>
            );
          }}
        </ResponsiveContext.Consumer>
      );

    case 'paragraph':
      return (
        <ResponsiveContext.Consumer key={`paragraph-${Math.random()}`}>
          {(size) => {
            return (
              <Text as="p" size={size}>
                {children}
              </Text>
            );
          }}
        </ResponsiveContext.Consumer>
      );

    case 'hyperlink':
      return serializeHyperlink(element, content);

    case 'image':
      return serializeImage(element);

    default:
      return null;
  }
}
