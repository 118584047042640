import React from 'react';
import { Box, Heading } from 'grommet';
import GatsbyButton from '../GatsbyButton';

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'medium', vertical: 'large' };
    case 'medium':
      return 'large';
    default:
      return { horizontal: 'xlarge', vertical: '51px' };
  }
}

export default function({ Link, header, size, ctaLabel, ctaLink }) {
  return (
    <Box background="dark-1" pad={mainPadding(size)}>
      <Box
        align="center"
        direction="row-responsive"
        gap="medium"
        justify="center"
        margin="auto"
        width={size === 'small' ? '100vw' : '1440px'}
      >
        <Heading size={size} level="5" margin="none" style={{ whiteSpace: 'pre-line' }} uppercase>
          {header}
        </Heading>
        <GatsbyButton Link={Link} label={ctaLabel} href={ctaLink} />
      </Box>
    </Box>
  );
}
// "https://phh.tbe.taleo.net/dispatcher/servlet/DispatcherServlet?org=POWERENGINEERS&act=redirectCws&cws=1"
