import React from 'react';
import { Box, Image, Heading, Text } from 'grommet';
import { GatsbyButton } from '../../../ui';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';

const ImageTitleContainer = styled(Heading)`
  margin: ${(props) => (props.size === 'small' ? '12px 0px 0px 0px' : '0px')};
  padding: ${(props) => (props.size === 'small' ? '20px' : '0px 0px 24px 0px')};
  color: #272727;
  text-align: center;
`;

const ImageBodyContainer = styled(Text)`
  color: #272727;
  text-align: center;
`;

export default function({
  size,
  header,
  blurb,
  background,
  htmlSerializer,
  cultureCtaLabel,
  cultureCtaLink,
}) {
  if (size === 'small') {
    return (
      <Box align="center" pad="medium" margin="xsmall" background="#F3F3F3">
        <Image fit="cover" src={background} style={{ width: '100vw', height: 'auto' }} />
        <ImageTitleContainer margin={{ top: 'medium' }} size={size} level="2">
          {header}
        </ImageTitleContainer>
        <ImageBodyContainer size={size}>
          <RichText render={blurb} htmlSerializer={htmlSerializer} />
        </ImageBodyContainer>
        <Box align="center" pad="small" margin="xsmall">
          <GatsbyButton
            Link={Link}
            href={cultureCtaLink}
            label={cultureCtaLabel}
            style={{ textAlign: 'center' }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      background={{
        size: 'auto',
        image: `url(${background})`,
      }}
    >
      <Box width="1440px" alignSelf="center" pad={{ horizontal: '222px', vertical: '243px' }}>
        <Box background="white" pad={{ horizontal: '102px', vertical: '70px' }}>
          <ImageTitleContainer size={size} level="2">
            {header}
          </ImageTitleContainer>
          <ImageBodyContainer size={size}>
            <RichText render={blurb} htmlSerializer={htmlSerializer} />
          </ImageBodyContainer>
          <Box align="center" pad="small" margin="xsmall">
            <GatsbyButton
              Link={Link}
              href={cultureCtaLink}
              label={cultureCtaLabel}
              style={{ textAlign: 'center' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
