import React, { useState } from 'react';
import { Box, Button, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { FormClose } from 'grommet-icons';
import styled from 'styled-components';
import LocationAccordion from './LocationAccordion';
import LocationCard from './LocationCard';
import MapFilter from '../MapFilter';
import SecondaryNav from '../SecondaryNav';
import { regionByState } from './utils';
import allRegions from './regions.json';

const { GATSBY_GOOGLE_MAPS_API_KEY } = process.env;

const SearchHeading = styled(Heading)`
  letter-spacing: 1.5px;
  line-height: 22px;
`;

const SearchBox = styled(Box)`
  position: absolute;
  transform: translateY(-100%);

  @media screen and (max-width: 768px) {
    position: relative;
    transform: translateY(0);
    width: 100%;
  }
`;

const ZipCodeText = styled.span`
  color: #0d7ea0;
`;

const ErrorText = styled.span`
  color: #ff5345;
`;

function findDist(id, locations) {
  return locations.reduce((acc, next) => (next.id === id ? next.dist : acc), 0);
}

export default function({ locations, coords }) {
  const [zipCode, setZipCode] = useState('');
  const [sortedLocations, setSortedLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const regions = allRegions
    .map((region) => ({
      ...region,
      locations: locations
        .filter((item) => regionByState(item.state) === region.title)
        .map((location) => {
          const id = `${location.coordinates.latitude}${location.coordinates.longitude}`;

          return {
            id,
            dist: sortedLocations && findDist(id, sortedLocations),
            ...location,
          };
        })
        .sort((a, b) =>
          a.dist > 0
            ? a.dist - b.dist
            : a.name.localeCompare(b.name) || a.city.localeCompare(b.city),
        ),
    }))
    .filter((region) => region.locations.length);

  const links = regions.map(({ id, title, link }) => ({ id, title, link }));

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';
        const isMedium = size === 'medium';

        return (
          <>
            <SearchBox
              width="600px"
              pad={{ horizontal: isSmall ? 'medium' : 'xlarge', vertical: '35px' }}
              background="dark-1"
            >
              <SearchHeading
                level="5"
                size="14px"
                margin={{ top: 'none', bottom: 'small' }}
                uppercase
              >
                Find Locations Near You
              </SearchHeading>
              <MapFilter
                apiKey={GATSBY_GOOGLE_MAPS_API_KEY}
                background="white"
                locations={coords}
                onSubmit={({ error, result, zipCode: zip }) => {
                  setErrorMessage(error ? error.message : '');
                  setZipCode(!error && zip ? zip : '');
                  setSortedLocations(!error && result ? result : null);
                }}
              />
            </SearchBox>
            {!isSmall && <SecondaryNav links={links} />}
            {(errorMessage || zipCode) && (
              <Box pad={{ horizontal: 'xlarge' }}>
                <Box width="1440px" margin="auto">
                  <Paragraph
                    margin={
                      isSmall
                        ? { vertical: 'medium', horizontal: 'auto' }
                        : { top: 'large', bottom: 'none' }
                    }
                    textAlign={isSmall && 'center'}
                    size={size}
                  >
                    {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                    {zipCode && (
                      <>
                        Showing results near <ZipCodeText>{zipCode}</ZipCodeText>
                        {isSmall && <br />}
                        <Button
                          icon={<FormClose plain size="medium" />}
                          label="clear"
                          margin={isSmall ? { top: 'xsmall' } : { left: '20px' }}
                          onClick={() => {
                            setErrorMessage('');
                            setSortedLocations(null);
                            setZipCode('');
                          }}
                          reverse
                          small
                        />
                      </>
                    )}
                  </Paragraph>
                </Box>
              </Box>
            )}
            {isSmall ? (
              <LocationAccordion regions={regions} size={size} />
            ) : (
              regions
                .sort((a, b) => a.locations[0].dist - b.locations[0].dist)
                .map((region, index) => (
                  <Box
                    background={index % 2 === 0 ? 'light-1' : 'light-3'}
                    id={region.link.replace('#', '')}
                    pad={{ horizontal: 'xlarge', vertical: '70px' }}
                  >
                    <Box width="1440px" margin="auto" size={size}>
                      <Heading level="2" size="large" margin="none">
                        {region.title}
                      </Heading>
                      <Box direction="row-responsive" wrap>
                        {region.locations.map((location) => (
                          <LocationCard
                            basis={isMedium ? '1/2' : '1/4'}
                            key={location.id}
                            location={location}
                            size={size}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                ))
            )}
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
