import styled from 'styled-components';
import { Text } from 'grommet';

export default styled(Text)`
  color: #272727;
  font-family: Roboto, sans-serif;
  font-style: bold;
  font-size: 16px;
  line-height: 22px;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
