import React from 'react';
import styled from 'styled-components';
import { Anchor, Box } from 'grommet';
import { Instagram, Linkedin, Youtube } from 'grommet-icons';
import { Glassdoor } from '@styled-icons/simple-icons/Glassdoor';

const StyledAnchor = styled(Anchor)`
  padding-right: 20px;
`;

export const StyledGlassdoor = styled(Glassdoor)`
  height: 24px;
  vertical-align: baseline;
`;

export default function SocialIcons({ links }) {
  return (
    <Box direction="row" pad="15px 0" justify="between">
      <StyledAnchor href={links.instagram} target="_blank">
        <Instagram color="white" size="medium" />
      </StyledAnchor>
      <StyledAnchor href={links.linkedin} target="_blank">
        <Linkedin color="white" size="medium" />
      </StyledAnchor>
      <StyledAnchor href={links.twitter} target="_blank">
        <img height="24" src="/images/x-white.png" alt="x icon" />
      </StyledAnchor>
      <StyledAnchor href={links.youtube} target="_blank">
        <Youtube color="white" size="medium" />
      </StyledAnchor>
      <Anchor href={links.glassdoor} target="_blank">
        <StyledGlassdoor />
      </Anchor>
    </Box>
  );
}
