import React from 'react';
import { Box, Image, ResponsiveContext } from 'grommet';
import { Download } from 'grommet-icons';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';

const CenterTxtButton = styled(GatsbyButton)`
  text-align: center;
  font-size: ${(props) => props.size === 'small' && '13px'};
`;

export default function MediaDownloadCard(mediaDownloadCardProps) {
  const isPdf = mediaDownloadCardProps.href.includes('.pdf');

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const boxPadding = size === 'small' ? 'large' : { vertical: 'large', horizontal: 'medium' };
        const width = size === 'small' || size === 'medium' ? '50%' : '31%';
        const marginMobile = size === 'small' || size === 'medium' ? 'auto' : 'small';
        return (
          <Box
            justify="center"
            width={width}
            direction="column"
            margin={marginMobile}
            background="dark-1"
            pad={boxPadding}
            height="100%"
          >
            <Image width="100%" src={mediaDownloadCardProps.src} />
            <CenterTxtButton
              fill="horizontal"
              margin={{ top: 'medium' }}
              href={mediaDownloadCardProps.href}
              dark
              reverse
              label={isPdf ? 'Download' : 'See the latest'}
              icon={isPdf ? <Download size={size === 'small' ? 'small' : 'medium'} /> : ''}
            />
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
