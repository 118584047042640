import React from 'react';
import { Box } from 'grommet';
import { StatHighlightsText, StatCaptionText } from '../../index';

export default function Stats({ stats }) {
  return (
    <Box pad={{ vertical: 'medium' }} basis="30%">
      <StatHighlightsText>{stats.highlightsProps}</StatHighlightsText>
      <StatCaptionText>{stats.captionProps}</StatCaptionText>
    </Box>
  );
}
