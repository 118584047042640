import styled from 'styled-components';

export default styled.div`
  text-align: center;

  div {
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 1280px) {
    p {
      max-width: 66%;
    }
  }
`;
