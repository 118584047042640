import React from 'react';

export default function() {
  return (
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
      <g fill="#272727" fillRule="evenodd">
        <path d="M12 3v2H5v20h20v-7h2v9H3V3h9z" />
        <path d="M23.524 4.99H16.04v-2h10.9v10.899h-2V6.403L14.624 16.717l-1.414-1.414z" />
      </g>
    </svg>
  );
}
