import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Paragraph } from 'grommet';
import { RichText } from 'prismic-reactjs';

const HeroContainer = styled(Box)`
  color: white;
  text-align: ${(props) => (props.size === 'small' ? 'left' : 'center')};

  p {
    max-width: 800px;
  }
`;

const HeroText = styled(Paragraph)`
  max-width: 800px;
  text-align: ${(props) => (props.size === 'small' ? 'left' : 'center')};
`;

const RichTextBox = styled(Box)`
  p {
    text-align: ${(props) => props.textAlign}
`;

export default function L2Hero({
  backgroundColor,
  backgroundURL,
  size,
  titleHead,
  titleText,
  titleHeadSize,
  htmlSerializer,
}) {
  const background = backgroundURL !== undefined ? `url(${backgroundURL})` : `#${backgroundColor}`;

  return (
    <HeroContainer
      align={size === 'small' ? '' : 'center'}
      size={size}
      background={background}
      direction="column"
      flex="false"
      pad={size === 'small' ? 'large' : 'xlarge'}
    >
      <Heading size={titleHeadSize ? titleHeadSize : size} level="1" margin="none">
        {titleHead}
      </Heading>
      {Array.isArray(titleText) ? (
        <RichTextBox textAlign={size === 'small' ? 'left' : 'center'}>
          <RichText render={titleText} htmlSerializer={htmlSerializer} />
        </RichTextBox>
      ) : (
        <HeroText size={size}>{titleText}</HeroText>
      )}
    </HeroContainer>
  );
}
