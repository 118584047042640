import styled from 'styled-components';
import { Box } from 'grommet';

export default styled(Box)`
  bottom: ${(props) => (props.bottom ? props.bottom : 0)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  position: ${(props) => (props.position ? props.position : 'sticky')};
  top: ${(props) => (props.top ? props.top : 0)};
`;
