import React from 'react';
import { Highlight, Snippet } from 'react-instantsearch-dom';
import GatsbyAnchor from '../GatsbyAnchor';

export const BaseHit = ({ hit, clickHandler, children }) => (
  <div>
    <GatsbyAnchor href={hit.route} onClick={clickHandler}>
      <h4>
        <Highlight attribute="name" hit={hit} tagName="mark" />
      </h4>
    </GatsbyAnchor>
    {children}
    <Snippet attribute="content" hit={hit} tagName="mark" />
  </div>
);

export const GeneralHit = (clickHandler) => ({ hit }) => <BaseHit {...{ clickHandler, hit }} />;
