import styled from 'styled-components';
import { Text } from 'grommet';

export default styled(Text).attrs(() => ({
  text: 'light',
}))`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-size: 24px;
  letter-spacing: 0.13px;
  line-height: 36px;
  font-weight: 300;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    letter-spacing: 0.1px;
    line-height: 28px;
  }
`;
