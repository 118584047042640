import React from 'react';
import { Helmet } from 'react-helmet';

export default function({ data, title, image, description = '', lang = 'en' }) {
  const titleTemplate = `%s | ${data.site.siteMetadata.title}`;
  return (
    <Helmet defer={false} htmlAttributes={{ lang }} title={title} titleTemplate={titleTemplate}>
      {description && <meta name="description" content={description} />}
      {image && <meta name="image" content={image} />}
      <meta prefix="og: http://ogp.me/ns#" property="og:type" content="website" />
      {title && <meta prefix="og: http://ogp.me/ns#" property="og:title" content={title} />}
      {description && (
        <meta prefix="og: http://ogp.me/ns#" property="og:description" content={description} />
      )}
      {image && <meta prefix="og: http://ogp.me/ns#" property="og:image" content={image} />}
      <meta name="twitter:card" content="summary" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      <link
        href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,500|Roboto:300,400,500,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}
