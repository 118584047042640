import React from 'react';
import styled from 'styled-components';
import { Box, Text, Heading } from 'grommet';

const StyledText = styled(Text)`
  max-width: ${(props) => (props.size === 'small' ? '310px' : '790px')};
  padding: ${(props) => (props.size === 'small' ? '0px 16px 40px 16px' : '0px 20px 60px 20px')};
  text-align: center;
`;

export default function({ size, header, blurb }) {
  if (size === 'small') {
    return (
      <Box align="center">
        <Heading size={size} level="1" margin={{ top: '3px', bottom: '15px' }}>
          {header}
        </Heading>
        <StyledText size={size}>
          <b>Legal Terms of Registration:</b>
          <br />
          {`${blurb}`}
        </StyledText>
      </Box>
    );
  }

  return (
    <Box align="center">
      <Heading size={size} level="1" margin={{ top: '11px', bottom: '20px' }}>
        {header}
      </Heading>
      <StyledText size={size}>
        <b>Legal Terms of Registration:</b> {`${blurb}`}
      </StyledText>
    </Box>
  );
}
