import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Image, Text, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import moment from 'moment';
import EyebrowBox from '../../typography/EyebrowBox';
import GatsbyAnchor from '../../components/GatsbyAnchor';
import ShareLinks from '../../components/ShareLinks';

const StyledHeading = styled(Heading)`
  margin: ${(props) => (props.size === 'small' ? '15px 0px' : '10px 0px 20px 0px')};
  font-weight: 300;
  font-size: ${(props) => {
    let value = '';
    switch (props.size) {
      case 'small':
        value = '36px';
        break;
      case 'medium':
        value = '44px';
        break;
      default:
        value = '54px';
        break;
    }

    return value;
  }};
  line-height: ${(props) => {
    let value = '';
    switch (props.size) {
      case 'small':
        value = '40px';
        break;
      case 'medium':
        value = '44px';
        break;
      default:
        value = '59px';
        break;
    }

    return value;
  }};
`;

const SubText = styled(Text)`
  color: #272727;
  font-family: Roboto, sans-serif;
  font-size: ${(props) => (props.size === 'small' ? '18px' : '20px')};
  line-height: 26px;
`;

const CaptionText = styled(Text)`
  & > * {
    color: #4b4b4b;
    color: #4b4b4b;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0.09px;
    line-height: 24px;
  }
`;

export default function PublicationHeader({
  Link,
  authorByline,
  caption,
  client,
  date,
  htmlSerializer,
  image,
  location,
  title,
  type,
}) {
  // Use moment for date handling simplicity
  const formattedDate = moment(date).format('MMMM Do, YYYY');

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';
        return (
          <Box flex={false}>
            <Box flex={false} pad={isSmall && { horizontal: 'medium' }}>
              <EyebrowBox
                Link={Link}
                as={GatsbyAnchor}
                href={`/library?type=${type}`}
                margin="none"
              >
                {type}
              </EyebrowBox>
              <StyledHeading size={size} level="1">
                {title}
              </StyledHeading>
              {['Article', 'Insight', 'Newsletter', 'White Paper', 'News'].includes(type) &&
                date && <SubText margin={{ bottom: 'medium' }}>{formattedDate}</SubText>}
              {authorByline && authorByline[0].text && (
                <SubText margin={{ bottom: 'medium' }}>
                  By: <b>{authorByline[0].text}</b>
                </SubText>
              )}
              {client && location && (
                <SubText margin={{ bottom: 'medium' }}>
                  <b>Client: </b> {client}
                  <br />
                  <b>Location: </b> {location}
                </SubText>
              )}
              <ShareLinks title={title} />
            </Box>
            <Box as="figure" margin={{ vertical: 'medium', horizontal: 'none' }}>
              <Image alt={image.alt || ''} fit="contain" src={image.url} width="100%" />
              {caption && (
                <Box flex={false} pad={isSmall && { horizontal: 'medium' }}>
                  <CaptionText as="figcaption">
                    <RichText render={caption} htmlSerializer={htmlSerializer} />
                  </CaptionText>
                </Box>
              )}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
