/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Text, Paragraph, Image, Select, ThemeContext } from 'grommet';
import { Down, LinkNext, LinkPrevious } from 'grommet-icons';
import styled from 'styled-components';

const TimelineContainer = styled(Box)`
  position: relative;
  background: linear-gradient(315deg, #b40000 0%, #e63d2f 100%);
  min-height: 850px;
  justify-content: center;
`;

const TimelineDataContainer = styled(Box)`
  .btnBox {
    width: 25%;
    margin: 0 auto;
    color: white;
  }

  width: ${(props) => (props.size !== 'large' ? 'unset' : '1440px')};
`;

const StyledTextContainer = styled(Box)`
  max-width: ${(props) => (props.size !== 'large' ? '275px' : '400px')};
  width: ${(props) => (props.size !== 'large' ? '275px' : '350px')};
  min-height: ${(props) => (props.size !== 'large' ? '' : '315px')};
  z-index: 10;
  background-color: #fff;
  height: fit-content;
  box-shadow: 5px 5px 14px 0 rgba(0, 0, 0, 0.22);
  justify-content: space-between;
`;

const RelativeBox = styled(Box)`
  position: relative;
`;

const AbsoluteSelectBox = styled(Box)`
  position: ${(props) => (props.size !== 'large' ? 'relative' : 'absolute')};
  right: 0;
  top: 0;
  box-shadow: 5px 5px 14px 0 rgba(0, 0, 0, 0.22);

  input::placeholder {
    color: #fff;
  }
`;

const StyledTimeline = styled(Box)`
  flex-direction: ${(props) => (props.size !== 'large' ? 'column-reverse' : 'row')};
  justify-content: center;
  align-self: center;
  height: ${(props) => (props.size !== 'large' ? '700px' : '530px')};
`;

const StyledImageContainer = styled(Box)`
  width: ${(props) => (props.size !== 'large' ? '100%' : '')};
  max-width: ${(props) => (props.size !== 'large' ? '275px' : '')};
  margin: ${(props) => (props.size !== 'large' ? '0' : '0 0 0 -30px')};
  justify-content: ${(props) => (props.size !== 'large' ? 'center' : '')};
  flex-direction: row;
  z-index: 0;
  height: ${(props) => (props.size !== 'large' ? '' : 'fit-content')};
  align-self: ${(props) => (props.size !== 'large' ? '' : 'center')};
`;

const StyledImage = styled(Image)`
  display: block;
  width: ${(props) => (props.size !== 'large' ? '-webkit-fill-available' : '')};
  box-shadow: 5px 5px 14px 0 rgba(0, 0, 0, 0.22);
`;

const TimelineBar = styled.div`
  z-index: 0;
  width: 100%;
  position: absolute;
  top: 52%;

  #backgroundLine {
    content: '';
    height: 5px;
    background-color: #fff;
  }

  .previousYear,
  .nextYear {
    font-family: Roboto, sans-serif;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: ${(props) => (props.size !== 'large' ? '14px' : '25px')};
    letter-spacing: ${(props) => (props.size !== 'large' ? '-0.33px' : '')};
    text-decoration: none;
    z-index: 10;
    width: 100%;

    :after {
      content: '';
      height: 30px;
      width: 3px;
      background-color: #000;
      margin-bottom: -5px;
    }
  }
`;

export default function Timeline({ size, data }) {
  const [currentItem, setCurrentItem] = useState(null);
  const [timeline, setTimeline] = useState(null);
  const [decades, setDecades] = useState(null);
  const [yearStart, setYearStart] = useState(9999);
  const [yearEnd, setYearEnd] = useState(0);

  function createTimeline() {
    const lastIndex = data.length - 1;
    const years = data?.map((item) => item.year);
    setYearStart(years[0]);
    setYearEnd(years[years.length - 1]);
    return data.map((item, index) => {
      const prevIndex = index - 1 < 0 ? null : index - 1;
      const nextIndex = index + 1 > lastIndex ? null : index + 1;
      const yearIndex = years.indexOf(item.year);
      const prevYear = typeof years[yearIndex - 1] !== 'undefined' ? years[yearIndex - 1] : null;
      const nextYear = typeof years[yearIndex + 1] !== 'undefined' ? years[yearIndex + 1] : null;

      return {
        decade: item.year - (item.year % 10),
        year: item.year,
        text: item.text,
        image: item.image,
        prevIndex,
        nextIndex,
        prevYear,
        nextYear,
      };
    });
  }
  function selectDecade(decade) {
    timeline.some((item, index) => {
      if (item.decade === decade) {
        setCurrentItem(timeline[index]);
        return true;
      }

      return false;
    });
  }

  useEffect(() => {
    const currentTimeline = createTimeline();
    setTimeline(currentTimeline);
    setCurrentItem(currentTimeline[0]);

    const allDecadesSet = new Set(currentTimeline.map((item) => item.decade));
    const allDecades = Array.from(allDecadesSet);
    setDecades(
      [...allDecades]?.map((decade) => ({
        text: `${decade}s`,
        value: decade,
      })),
    );
  }, []);

  const isLarge = size === 'large';
  return (
    <>
      {currentItem && (
        <TimelineContainer
          margin={{ vertical: 'large' }}
          onClick={() => {
            window.dataLayer.push({
              event: 'GAEvent',
              eventCategory: 'Timeline interaction',
              eventAction: 'Click',
            });
          }}
        >
          <TimelineBar size={size}>
            {/* Background white line with years */}
            <Box
              direction="row"
              justify="between"
              width={{ max: '1440px' }}
              margin={{ horizontal: 'auto' }}
            >
              <div style={{ marginLeft: '10px' }}>
                {currentItem.prevYear !== null && (
                  <Text className="previousYear">{currentItem.prevYear}</Text>
                )}
              </div>
              <div style={{ marginRight: '10px' }}>
                {currentItem.nextYear !== null && (
                  <Text className="nextYear">{currentItem.nextYear}</Text>
                )}
              </div>
            </Box>
            <div id="backgroundLine" />
          </TimelineBar>
          <TimelineDataContainer size={size} margin={{ horizontal: 'auto' }}>
            {/* Start Text Container */}
            <RelativeBox>
              <Heading
                level="2"
                alignSelf="center"
                color="#fff"
                textAlign="center"
                margin={{ top: '0px', bottom: 'medium' }}
              >
                Our History
              </Heading>
              <Text alignSelf="center" textAlign="center" margin={{ bottom: 'medium' }}>
                {yearStart} - {yearEnd}
              </Text>
              <AbsoluteSelectBox background="#0C0C0C" size={size}>
                <ThemeContext.Extend
                  value={{
                    button: {
                      extend: {
                        'text-transform': 'lowercase',
                        'font-family': 'Roboto, sans-serif',
                        'font-size': '16px',
                      },
                    },
                  }}
                >
                  <Select
                    labelKey="text"
                    placeholder="Decade Directory"
                    options={decades}
                    icon={<Down color="#fff" />}
                    onChange={({ option }) => selectDecade(option.value)}
                  />
                </ThemeContext.Extend>
              </AbsoluteSelectBox>
            </RelativeBox>
            {/* End Text Container */}

            {/* Start Timeline Container */}
            <StyledTimeline size={size} margin={{ vertical: '40px' }}>
              <div style={{ zIndex: '5', alignSelf: 'center' }}>
                <StyledTextContainer
                  size={size}
                  pad={{ top: 'large', bottom: 'medium', horizontal: 'large' }}
                >
                  <div>
                    <Heading level="3" margin={{ vertical: '0' }}>
                      {currentItem.year}
                    </Heading>
                    <Paragraph>{currentItem.text}</Paragraph>
                  </div>
                </StyledTextContainer>
              </div>
              <StyledImageContainer size={size}>
                <StyledImage
                  size={size}
                  src={!isLarge ? currentItem.image.mobile.url : currentItem.image.url}
                />
              </StyledImageContainer>
            </StyledTimeline>
            {/* End Timeline Container */}
            <div>
              <Box direction="row" justify="between" className="btnBox">
                <div>
                  {currentItem.prevIndex !== null && (
                    <Button
                      icon={<LinkPrevious size="25px" />}
                      onClick={() => setCurrentItem(timeline[currentItem.prevIndex])}
                    />
                  )}
                </div>
                <div>
                  {currentItem.nextIndex !== null && (
                    <Button
                      icon={<LinkNext size="25px" />}
                      onClick={() => setCurrentItem(timeline[currentItem.nextIndex])}
                    />
                  )}
                </div>
              </Box>
            </div>
          </TimelineDataContainer>
        </TimelineContainer>
      )}
    </>
  );
}
