import React from 'react';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { Picture, EyebrowText, AnchorArrow, GatsbyAnchor } from '../../index';

export default function CurrentsCard({ currents, Link }) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        if (sizeResponsive === 'small') {
          return (
            <Box direction="row" pad={{ vertical: 'medium' }} basis="100%">
              <Picture width="90px" height="100%" src={currents.image} alt={currents.imageAlt} />
              <Box pad={{ horizontal: 'medium' }}>
                <div>
                  <EyebrowText margin={{ top: 'medium' }} color="brand">
                    {currents.category}
                  </EyebrowText>
                </div>
                <Heading size={sizeResponsive} level="3" margin="0px">
                  {currents.title}
                </Heading>
                <div>
                  <GatsbyAnchor Link={Link} href={currents.to}>
                    Read more
                    <AnchorArrow />
                  </GatsbyAnchor>
                </div>
              </Box>
            </Box>
          );
        }
        return (
          <Box direction="column" pad="small" width="33%">
            <Picture width="100%" src={currents.image} alt={currents.imageAlt || ''} />
            <EyebrowText margin={{ top: 'medium' }} color="brand">
              {currents.category}
            </EyebrowText>
            <Heading level="4" margin={{ vertical: '10px' }}>
              {currents.title}
            </Heading>
            <Paragraph margin={{ top: '0px' }}>{currents.excerpt}</Paragraph>
            <div>
              <GatsbyAnchor Link={Link} href={currents.to}>
                Read more
                <AnchorArrow />
              </GatsbyAnchor>
            </div>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
