import React from 'react';
import { Button } from 'grommet';
import { isEmail, isExternal, forceSlashes } from '../../utils/url';

export default function({ Link, href, ...rest }) {
  const { document } = rest;
  if (!href) return null;

  if (isEmail(href)) return <Button as="a" href={href} {...rest} />;

  if (isExternal(href) || document)
    return <Button as="a" href={href} target="_blank" rel="noopener noreferrer" {...rest} />;

  const url = forceSlashes(href);

  return <Button as={Link || 'a'} to={!Link ? null : url} href={!Link ? url : null} {...rest} />;
}
