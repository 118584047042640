import React from 'react';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import MarketSelector from '../../components/MarketSelector';
import { Picture } from '../../components/Picture';
import HeroArrowIcon from './HeroArrowIcon';

export default function Hero({
  Link,
  heroHeader,
  heroCopy,
  isAbout,
  imageDesk,
  imageMobile,
  markets,
  marketSelectorBody,
  htmlSerializer,
  isMarkets,
}) {
  const isHome = marketSelectorBody && markets;
  const mobileBackground = isAbout ? 'light-3' : '';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isMedium = size === 'medium';

        if (size === 'small') {
          return (
            <Box background={mobileBackground}>
              <Picture src={imageMobile} width="100%" height="auto" />
              <Box margin="medium">
                <Heading margin={{ bottom: '5px' }} level="1">
                  {heroHeader}
                </Heading>
                {Array.isArray(heroCopy) ? (
                  <RichText render={heroCopy} htmlSerializer={htmlSerializer} size={size} />
                ) : (
                  <Paragraph size={size} fill>
                    {heroCopy}
                  </Paragraph>
                )}
              </Box>

              {isHome && (
                <Box pad="medium" background="dark-1">
                  <Heading size={size} level="6" color="white">
                    {marketSelectorBody}
                  </Heading>
                  <MarketSelector isHomePage markets={markets} />
                </Box>
              )}
            </Box>
          );
        }

        return (
          <Box
            background={`url(${imageDesk})`}
            pad={{
              horizontal: isMedium ? 'large' : 'xlarge',
              vertical: isMedium ? 'large' : 'xlarge',
              bottom: isHome && 'none',
            }}
            height={{ min: '540px' }}
          >
            <Box width="1440px" alignSelf="center" margin={{ horizontal: 'auto' }}>
              <Box background="white" width="588px" justify="start">
                <Box pad="large">
                  <Heading margin="none" size={size} level="1">
                    {heroHeader}
                  </Heading>
                  {Array.isArray(heroCopy) ? (
                    <RichText render={heroCopy} htmlSerializer={htmlSerializer} />
                  ) : (
                    <Paragraph>{heroCopy}</Paragraph>
                  )}
                  {isMarkets && (
                    <Box>
                      <HeroArrowIcon />
                    </Box>
                  )}
                </Box>
                {isHome && (
                  <Box
                    pad={{ horizontal: '50px', top: '25px', bottom: '50px' }}
                    background="dark-1"
                  >
                    <Heading size={size} level="6" color="white">
                      {marketSelectorBody}
                    </Heading>
                    <MarketSelector Link={Link} markets={markets} isHomePage />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
