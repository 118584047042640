import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionPanel, Box } from 'grommet';
import LocationCard from './LocationCard';

const StyledAccordionPanel = styled(AccordionPanel)`
  ${({ active }) => (active ? 'background: #C4EBF6;' : '')}
  position: relative;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    border-bottom: 1px solid #272727;
  }

  h4 {
    color: #272727 !important;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? '700' : 'normal')};
    line-height: 20px;
    margin: 20px 0;
    text-transform: none;
  }

  svg {
    color: #272727 !important;
  }
`;

export default function({ regions }) {
  const [activeIndex, setActiveIndex] = useState([0]);

  return (
    <Accordion
      activeIndex={activeIndex}
      margin={{ bottom: 'large' }}
      onActive={(index) => setActiveIndex(index)}
      multiple
      border="top"
    >
      {regions &&
        regions
          .sort((a, b) => a.locations[0].dist - b.locations[0].dist)
          .map((region, index) => {
            const active = activeIndex.includes(index);

            return (
              <StyledAccordionPanel
                label={region.title}
                key={`region-${region.id}`}
                pad={{ horizontal: 'medium' }}
                active={active}
              >
                <Box margin={{ vertical: 'medium' }} flex={false}>
                  {region.locations.map((location) => (
                    <LocationCard
                      key={location.id}
                      location={location}
                      size="small"
                      margin={{ vertical: 'medium' }}
                      pad={{ horizontal: 'medium' }}
                    />
                  ))}
                </Box>
              </StyledAccordionPanel>
            );
          })}
    </Accordion>
  );
}
