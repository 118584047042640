import React from 'react';

export default function({ fill = 'currentColor', ...rest }) {
  return (
    <svg width="22" height="12" viewBox="0 0 22 12" {...rest}>
      <path
        fill={fill}
        d="M19.96 5.5l-3.35-4.188.78-.624L21.64 6l-4.25 5.312-.78-.624L19.96 6.5H0v-1z"
      />
    </svg>
  );
}
