import React from 'react';
import { Box, Heading, Image, ResponsiveContext, Paragraph } from 'grommet';
import { RichText } from 'prismic-reactjs';
import { mainPadding, mainGap } from '../../utils/layout';

export default function CityworksPowerBlock({
  image1,
  image2,
  body2Text,
  body3Text,
  header1Text,
  header2Text,
  header3Text,
  htmlSerializer,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const padSection =
          sizeResponsive === 'small' ? { vertical: 'small' } : { vertical: 'xlarge' };
        const mobileGap = sizeResponsive === 'small' ? 'xlarge' : '';
        return (
          <Box background="#F3F3F3">
            <Box width="1440px" margin="auto" pad={mainPadding(sizeResponsive)} gap={mobileGap}>
              <Heading margin="0px" size={sizeResponsive} level={2} alignSelf="center">
                {header1Text}
              </Heading>
              <Box
                gap={mainGap(sizeResponsive)}
                direction="row-responsive"
                pad={padSection}
              >
                <Box basis="1/2" responsive>
                  <Image width="100%" src={image1.url} alt={image1.alt || ''} />
                </Box>
                <Box basis="1/2">
                  <Heading size={sizeResponsive} level={3} margin={{ top: '0px', bottom: 'small' }}>
                    {header2Text}
                  </Heading>
                  {Array.isArray(body2Text) ? (
                    <RichText
                      render={body2Text}
                      htmlSerializer={htmlSerializer}
                      size={sizeResponsive}
                    />
                  ) : (
                    <Paragraph margin="0" size={sizeResponsive} fill>
                      {body2Text}
                    </Paragraph>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
