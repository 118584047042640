// This sets the letter spacing for each Heading break level and breakpoint.
const letterSpace = {
  1: {
    small: '-1px',
    medium: '-1.22px',
    large: '-1.45px',
  },
  2: {
    small: '-0.47px',
    medium: '-0.57px',
    large: '-0.7px',
  },
  3: {
    small: '-0.42px',
    medium: '-0.47px',
    large: '-0.47px',
  },
  4: {
    small: '0',
    medium: '0',
    large: '0',
  },
  5: {
    small: '1.11px',
    medium: '1.11px',
    large: '1.46px',
  },
  6: {
    small: '0',
    medium: '0',
    large: '0',
  },
};

function anchorArrowStyle({ theme }) {
  const color = theme.global.colors[theme.dark ? 'light-1' : 'dark-1'];

  return `
  & svg {
    color: ${color};
  }`;
}

function buttonHoverStyle({ theme, primary }) {
  const color = theme.global.colors[theme.dark ? 'neutral-3' : 'neutral-1'];

  return `
  &:hover {
    ${!primary ? `color: ${color}` : ''};
    ${primary ? `background-color: ${color}` : ''};
    border-color: ${color};
    box-shadow: none;
  }`;
}

function uppercaseStyle({ uppercase }) {
  return !uppercase ? '' : 'text-transform: uppercase;';
}

function letterSpacing({ level, size }) {
  return level && size ? `letter-spacing: ${letterSpace[level][size]}` : '';
}

function boxMainMargin({ mainMargin }) {
  return !mainMargin ? '' : 'margin-left: 5%; margin-right: 5%';
}

export default {
  global: {
    breakpoints: {
      small: {
        value: 768,
      },
      medium: {
        value: 1023,
      },
    },
    colors: {
      black: '#0D0E12',
      brand: '#E63D2F',
      focus: '#4ECBEF',
      white: '#FFFFFF',
      border: {
        dark: '#FFFFFF',
        light: '#272727',
      },
      icon: {
        dark: '#FFFFFF',
        light: '#272727',
      },
      text: {
        dark: '#FFFFFF',
        light: '#272727',
      },
      'accent-1': '#FF5345',
      'accent-2': '#CB361D',
      'accent-3': '#1082A4',
      'dark-0': '#0C0C0C',
      'dark-1': '#272727',
      'dark-2': '#4B4B4B',
      'dark-3': '#A7A8AA',
      'dark-4': '#333333',
      'light-1': '#FFFFFF',
      'light-2': '#D9D9D6',
      'light-3': '#F3F3F3',
      'neutral-1': '#0D7EA0',
      'neutral-2': '#1DA7CF',
      'neutral-3': '#4ECBEF',
      'neutral-4': '#C4EBF6',
      'neutral-5': '#064658',
      'status-critical': '#FF5345',
      'status-error': '#FF5345',
    },
    control: {
      border: {
        radius: undefined,
      },
    },
    font: {
      family: 'Roboto Slab, serif',
    },
    input: {
      weight: 400,
    },
    hover: {
      background: {
        color: 'neutral-2',
      },
      color: {
        dark: 'accent-1',
        light: 'neutral-2',
      },
    },
  },
  anchor: {
    color: {
      dark: 'neutral-3',
      light: 'accent-3',
    },
    fontWeight: 400,
    textDecoration: 'underline',
    hover: {
      textDecoration: 'none',
    },
    extend: (props) => anchorArrowStyle(props),
  },
  button: {
    border: {
      radius: undefined,
      width: '1px',
      color: {
        dark: 'light-1',
        light: 'dark-1',
      },
    },
    padding: {
      vertical: '12px',
      horizontal: '38px',
    },
    primary: {
      color: {
        dark: 'light-1',
        light: 'dark-1',
      },
    },
    extend: (props) => {
      let extraStyles = '';

      if (props.small) {
        extraStyles += `
          font-size: 14px;
          padding: 4px 16px;
        `;
      }

      return `
        text-transform: uppercase;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 21px;
        ${buttonHoverStyle(props)}
        ${extraStyles}

        & svg {
          fill: currentColor;
          stroke: currentColor;
        }
      `;
    },
  },
  box: {
    extend: (props) => {
      return `
        ${boxMainMargin(props)}
      `;
    },
  },
  heading: {
    font: {
      family: 'Roboto, sans-serif',
    },
    weight: 500,
    level: {
      '1': {
        small: {
          size: '44px',
          height: '48px',
          maxWidth: '1440px',
        },
        medium: {
          size: '54px',
          height: '50px',
          maxWidth: '1440px',
        },
        large: {
          size: '64px',
          height: '68px',
          maxWidth: '1440px',
        },
      },
      '2': {
        font: { weight: 300 },
        small: {
          size: '36px',
          height: '40px',
          maxWidth: '1440px',
        },
        medium: {
          size: '44px',
          height: '44px',
          maxWidth: '1440px',
        },
        large: {
          size: '54px',
          height: '59px',
          maxWidth: '1440px',
        },
      },
      '3': {
        small: {
          size: '32px',
          height: '42px',
          maxWidth: '1440px',
        },
        medium: {
          size: '36px',
          height: '42px',
          maxWidth: '1440px',
        },
        large: {
          size: '36px',
          height: '42px',
          maxWidth: '1440px',
        },
      },
      '4': {
        small: {
          size: '20px',
          height: '26px',
          maxWidth: '1440px',
        },
        medium: {
          size: '28px',
          height: '36px',
          maxWidth: '1440px',
        },
        large: {
          size: '28px',
          height: '36px',
          maxWidth: '1440px',
        },
      },
      '5': {
        small: {
          size: '20px',
          height: '26px',
          maxWidth: '1440px',
        },
        medium: {
          size: '20px',
          height: '26px',
          maxWidth: '1440px',
        },
        large: {
          size: '26px',
          height: '32px',
          maxWidth: '1440px',
        },
      },
      '6': {
        small: {
          size: '18px',
          height: '26px',
          maxWidth: '1440px',
        },
        medium: {
          size: '18px',
          height: '26px',
          maxWidth: '1440px',
        },
        large: {
          size: '20px',
          height: '26px',
          maxWidth: '1440px',
        },
      },
    },
    extend: (props) => {
      return `
        ${uppercaseStyle(props)}
        ${letterSpacing(props)}
      `;
    },
  },
  select: {
    icons: {
      color: {
        dark: 'light-1',
        light: 'dark-1',
      },
    },
    options: {
      text: {
        color: 'dark-1',
        size: '16px',
        weight: 'normal',
        family: 'Roboto Slab',
      },
    },
    container: {
      extend: () => `
        background-color: #fff;
      `,
    },
  },
  text: {
    font: {
      family: 'Roboto Slab',
    },
    weight: 500,
    small: {
      size: '16px',
      height: '28px',
    },
    medium: {
      size: '18px',
      height: '30px',
    },
    large: {
      size: '18px',
      height: '30px',
    },
    extend: () => `
      letter-spacing: 0.1px;
    `,
  },
  paragraph: {
    font: {
      family: 'Roboto Slab',
    },
    weight: 500,
    small: {
      size: '16px',
      height: '28px',
    },
    medium: {
      size: '18px',
      height: '30px',
    },
    large: {
      size: '18px',
      height: '30px',
    },
    extend: () => `
      letter-spacing: 0.1px;
    `,
  },
  carousel: {
    icons: {
      color: {
        dark: 'brand',
        light: 'brand',
      },
    },
  },
  formField: {
    border: {
      position: 'none',
    },
  },
  layer: {
    overlay: {
      background: 'rgba(230,61,47,0.9)',
    },
  },
};
