/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  FormField,
  Heading,
  Select,
  ResponsiveContext,
  TextInput,
  ThemeContext,
} from 'grommet';
import { Close, Down, Search } from 'grommet-icons';
import styled from 'styled-components';
import FilterIcon from './FilterIcon';

const LibraryFilterContainer = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const StyledFormField = styled(FormField)`
  width: ${(props) => (props.size === 'small' ? '100%' : '20%')};
  & label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    margin-left: 0;
    font-weight: 500;
  }
`;

const SearchContainer = styled(Box)`
  position: relative;
`;

const SearchIcon = styled(Search)`
  position: absolute;
  top: 0;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

function stringToOption(str) {
  return { text: str, value: str };
}

export default function({ onChange, services, markets, regions, types, values }) {
  const ref = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  function adjustHeight() {
    const header = document.querySelector('#header');
    if (header) setHeaderHeight(header.clientHeight);
  }

  const [market, setMarket] = useState(values ? stringToOption(values.market) : {});
  const [region, setRegion] = useState(values ? stringToOption(values.region) : {});
  const [service, setService] = useState(values ? stringToOption(values.service) : {});
  const [term, setTerm] = useState(values ? values.term : '');
  const [type, setType] = useState(values ? stringToOption(values.type) : {});

  function filterObject() {
    const result = {};

    if (type.value) result.type = type.value;
    if (market.value) result.market = market.value;
    if (region.value) result.region = region.value;
    if (service.value) result.service = service.value;
    if (term) result.term = term;

    return result;
  }

  const [filters, setFilters] = useState(filterObject());
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => setFilters(filterObject()), [type, market, region, service, term]);

  useEffect(() => filters && onChange && onChange(filters), [filters]);

  useEffect(() => {
    adjustHeight();
    window.addEventListener('resize', adjustHeight, { passive: true });
    return () => window.removeEventListener('resize', adjustHeight);
  }, []);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';

        return (
          <LibraryFilterContainer
            ref={ref}
            pad={{ horizontal: 'large', vertical: 'medium' }}
            width="100%"
            align="center"
            background="light-3"
            style={headerHeight && !isSmall ? { top: `${headerHeight}px` } : null}
          >
            <Box width="1440px" margin="auto">
              {isSmall && (
                <Box width="full" direction="row" align="center" justify="between">
                  <Heading level="6" size="14px" margin="none">
                    View Filters
                  </Heading>
                  <Button
                    icon={showFilters ? <Close size="32px" /> : <FilterIcon />}
                    onClick={() => setShowFilters(!showFilters)}
                    plain
                  />
                </Box>
              )}
              <ThemeContext.Extend
                value={{
                  global: {
                    active: { color: 'dark-1' },
                    hover: {
                      color: {
                        dark: 'dark-1',
                        light: 'dark-1',
                      },
                    },
                  },
                  button: { extend: () => null },
                }}
              >
                {(!isSmall || (isSmall && showFilters)) && (
                  <Box width="full" direction="row-responsive" gap="small">
                    <StyledFormField size={size} label="Search">
                      <SearchContainer background="white">
                        <SearchIcon color="neutral-2" size="medium" />
                        <TextInput
                          background={<Search color="neutral-2" size="medium" />}
                          size="small"
                          placeholder="Enter keyword"
                          value={term}
                          onChange={(e) => setTerm(e.target.value)}
                          style={{ paddingRight: '48px' }}
                        />
                      </SearchContainer>
                    </StyledFormField>
                    {services && (
                      <StyledFormField size={size} label="Service">
                        <Box background="white">
                          <Select
                            labelKey="text"
                            onChange={({ option }) => setService(option)}
                            options={[{ text: 'All Services', value: '' }, ...services]}
                            placeholder="Select a service"
                            value={service}
                            valueKey="value"
                            size="small"
                            icon={<Down color="neutral-2" />}
                          />
                        </Box>
                      </StyledFormField>
                    )}
                    {markets && (
                      <StyledFormField size={size} label="Industry">
                        <Box background="white">
                          <Select
                            labelKey="text"
                            onChange={({ option }) => setMarket(option)}
                            options={[{ text: 'All Industries', value: '' }, ...markets]}
                            placeholder="Select an industry"
                            value={market}
                            valueKey="value"
                            size="small"
                            icon={<Down color="neutral-2" />}
                          />
                        </Box>
                      </StyledFormField>
                    )}
                    {regions && (
                      <StyledFormField size={size} label="Region">
                        <Box background="white">
                          <Select
                            labelKey="text"
                            onChange={({ option }) => setRegion(option)}
                            options={[{ text: 'All Regions', value: '' }, ...regions]}
                            placeholder="Select a region"
                            value={region}
                            valueKey="value"
                            size="small"
                            icon={<Down color="neutral-2" />}
                          />
                        </Box>
                      </StyledFormField>
                    )}
                    {types && (
                      <StyledFormField size={size} label="Content Type">
                        <Box background="white">
                          <Select
                            labelKey="text"
                            onChange={({ option }) => setType(option)}
                            options={[{ text: 'All Content Types', value: '' }, ...types]}
                            placeholder="Select a content type"
                            value={type}
                            valueKey="value"
                            size="small"
                            icon={<Down color="neutral-2" />}
                          />
                        </Box>
                      </StyledFormField>
                    )}
                  </Box>
                )}
              </ThemeContext.Extend>
            </Box>
          </LibraryFilterContainer>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
