import React from 'react';
import styled from 'styled-components';
import { Box, Text, Image, Heading } from 'grommet';
import { RichText } from 'prismic-reactjs';

const BenefitsTitle = styled(Heading)`
  margin: ${(props) => (props.size === 'small' ? '12px 0px 0px 0px' : '96px 0px 0px 0px')};
  padding: ${(props) => (props.size === 'small' ? '20px' : '0px 0px 24px 0px')};
  color: #272727;
`;

const BenefitsBody = styled(Text)`
  width: ${(props) => (props.size === 'small' ? 'auto' : '636px')};
  color: #272727;
  text-align: center;
`;

const StyledIcon = styled(Image)`
  width: ${(props) => (props.size === 'small' ? '50px' : '77px')};
  height: ${(props) => (props.size === 'small' ? '50px' : '77px')};
  padding: 20px;
`;

const IconSubscript = styled(Heading)`
  width: ${(props) => (props.size === 'small' ? '100%' : '200px')};
  margin: ${(props) => (props.size === 'small' ? '6px 0px 0px 0px' : '12px 0px 0px 0px')};
  text-align: center;
`;

export default function({ size, header, blurb, benefits, htmlSerializer }) {
  const MappedBenefits = benefits.map((elem) => {
    return (
      <Box key={elem.id} pad="medium" align="center" width={size === 'small' ? '50%' : '25%'}>
        <StyledIcon src={elem.icon} size={size} />
        <IconSubscript size={size} level="6">
          {elem.text}
        </IconSubscript>
      </Box>
    );
  });

  if (size === 'small') {
    return (
      <Box pad="medium">
        <Box align="center">
          <BenefitsTitle size={size} level="2">
            {header}
          </BenefitsTitle>
          <BenefitsBody size={size}>
            <RichText render={blurb} htmlSerializer={htmlSerializer} />
          </BenefitsBody>
        </Box>

        <Box direction="row" wrap>
          {MappedBenefits}
        </Box>
      </Box>
    );
  }

  return (
    <Box pad={{ bottom: 'large' }}>
      <Box align="center">
        <BenefitsTitle size={size} level="2">
          {header}
        </BenefitsTitle>
        <BenefitsBody size={size}>
          <RichText render={blurb} htmlSerializer={htmlSerializer} />
        </BenefitsBody>
      </Box>
      <Box
        direction="row"
        wrap
        margin={{ top: 'large', bottom: 'large', horizontal: 'xlarge' }}
        width="1440px"
        alignSelf="center"
      >
        {MappedBenefits}
      </Box>
    </Box>
  );
}
