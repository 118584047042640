import React from 'react';
import {
  Box,
  Button,
  Form,
  FormField,
  TextArea,
  RadioButtonGroup,
  Select,
  CheckBox,
} from 'grommet';
import styled from 'styled-components';
import SupplierRegHeader from '../SupplierRegHeader';
import SuccessMessage from './SuccessMessage';
import HubspotPost from '../HubspotPost';

const States = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virgina',
  'Wisconsin',
  'Wyoming',
];

const StyledBox = styled(Box)`
  background-color: #272727;
  font-family: Roboto;
  font-size: ${(props) => (props.size === 'small' ? '16px' : '18px')};
  letter-spacing: ${(props) => (props.size === 'small' ? '0.09px' : '0.1px')};
  line-height: ${(props) => (props.size === 'small' ? '28px' : '30px')};
  font-weight: bold;
  color: white;
  width: 100%;
  align-items: center;
  padding: 20px 0;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledFormField = styled(FormField)`
  label {
    margin-left: 0px;
    color: #272727;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
  div:first-child,
  textarea {
    border: 1px solid #272727;
  }
  div:first-child {
    div {
      border: none;
    }
  }
`;

const StyledFormFieldRadioGroup = styled(FormField)`
  label {
    margin-left: 0;
  }
  div {
    label {
      margin-top: 15px;
    }
  }
`;

const StyledP = styled.p`
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.08px;
  line-height: 22px;
  color: #727272;
`;

const StyledError = styled.pre`
  color: #ff5345;
  font-family: inherit;
  white-space: break-spaces;
`;

export default function SupplierRegistrationForm({
  size,
  regHeader,
  regBlurb,
  successBody,
  successButtonLabel,
  successButtonTo,
  successHeading,
}) {
  const { isError, handleSubmit, errorData, submitted } = HubspotPost({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: '69c19dbb-512f-49e9-a5c3-54fc0f9f9035',
  });

  const fifty = size === 'small' ? '100%' : '50%';
  const thirtyThree = size === 'small' ? '100%' : '33%';

  return (
    <Box>
      {isError === false && !errorData && submitted === true ? (
        <SuccessMessage
          successBody={successBody}
          successButtonLabel={successButtonLabel}
          successButtonTo={successButtonTo}
          successHeading={successHeading}
        />
      ) : (
        <>
          <SupplierRegHeader size={size} header={regHeader} blurb={regBlurb} />
          <StyledForm onSubmit={handleSubmit}>
            <StyledBox size={size}>Company Information</StyledBox>
            <Box direction="row-responsive" wrap pad={{ vertical: 'small', horizontal: 'xlarge' }}>
              <StyledP>* Required Fields</StyledP>
            </Box>
            <Box
              direction="row-responsive"
              wrap
              pad={{ vertical: '60px', horizontal: 'xlarge', top: 'small' }}
            >
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField width={fifty} label="Company Name *" name="company" required />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Doing Business As" name="doing_business_as" />
              </Box>
              <Box width="100%" pad={{ horizontal: 'small' }}>
                <StyledFormField label="Address *" name="address" required />
              </Box>
              <Box width={thirtyThree} pad={{ horizontal: 'small' }}>
                <StyledFormField label="City *" name="city" required />
              </Box>
              <Box width={thirtyThree} pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="State/Region *"
                  name="state"
                  component={Select}
                  options={States}
                  required
                />
              </Box>
              <Box width={thirtyThree} pad={{ horizontal: 'small' }}>
                <StyledFormField label="ZIP Code *" name="zip" required />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Website URL" name="website" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="DUNS Number" name="dun_and_bradstreet_number" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="EIN" name="ein" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Primary NAICS Code" name="primary_naics_code" />
              </Box>
            </Box>
            <StyledBox size={size}>Company Contact Information</StyledBox>
            <Box direction="row-responsive" wrap pad={{ vertical: '60px', horizontal: 'xlarge' }}>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="First Name *" name="firstname" required />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Last Name *" name="lastname" required />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="Contact Phone Number *"
                  name="phone"
                  placeholder="XXX-XXX-XXXX"
                  required
                />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Email *" name="email" required />
              </Box>
            </Box>
            <StyledBox size={size}>Specialty and History</StyledBox>
            <Box direction="row-responsive" wrap pad={{ vertical: '60px', horizontal: 'xlarge' }}>
              <Box width="100%" pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="Detailed Description of Product/Service Provided*"
                  name="detailed_description"
                  component={TextArea}
                  required
                />
              </Box>
              <Box direction="row-responsive" width="100%">
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormFieldRadioGroup
                    label="Have you worked with POWER Engineers in the past? *"
                    name="history_checkbox2"
                    id="history_checkbox2"
                    direction="row"
                    component={RadioButtonGroup}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                    required
                  />
                </Box>
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormField
                    label="If yes, please provide the contract number"
                    name="contract_number"
                  />
                </Box>
              </Box>
            </Box>
            <StyledBox size={size}>Safety and Coverage</StyledBox>
            <Box direction="row-responsive" wrap pad={{ vertical: '60px', horizontal: 'xlarge' }}>
              <Box direction="row-responsive" width="100%">
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormFieldRadioGroup
                    label="Do you have a safety program? *"
                    name="safety_program"
                    id="safety_program"
                    direction="row"
                    component={RadioButtonGroup}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                    required
                  />
                </Box>
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormField
                    width={fifty}
                    label="What is your EMR for the last 3 years?"
                    name="emr"
                  />
                </Box>
              </Box>
              <Box margin={{ vertical: 'medium' }}>
                Please indicate which of the following insurance coverages your firm possesses:
              </Box>
              <Box direction="row-responsive" width="100%">
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormFieldRadioGroup
                    label="Professional Liability Insurance"
                    name="professional_liability_insurance"
                    direction="row"
                    component={RadioButtonGroup}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                  />
                </Box>
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormField
                    label="If yes, what is your coverage limit?"
                    name="professional_liability_coverage"
                  />
                </Box>
              </Box>
              <Box direction="row-responsive" width="100%">
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormFieldRadioGroup
                    label="General Liability Insurance"
                    name="general_liability_insurance"
                    direction="row"
                    component={RadioButtonGroup}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                  />
                </Box>
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormField
                    label="If yes, what is your coverage limit?"
                    name="general_liability_coverage"
                  />
                </Box>
              </Box>
              <Box direction="row-responsive" width="100%">
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormFieldRadioGroup
                    label="Workers Compensation Insurance"
                    name="workers_compensation_insurance"
                    direction="row"
                    component={RadioButtonGroup}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                  />
                </Box>
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormField
                    label="If yes, what is your coverage limit?"
                    name="workers_compensation_coverage"
                  />
                </Box>
              </Box>
              <Box direction="row-responsive" width="100%">
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormFieldRadioGroup
                    label="Automotive Liability Insurance"
                    name="automobile_liability_insurance"
                    direction="row"
                    component={RadioButtonGroup}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                  />
                </Box>
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormField
                    label="If yes, what is your coverage limit?"
                    name="automobile_liability_coverage"
                  />
                </Box>
              </Box>
              <Box direction="row-responsive" width="100%">
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormFieldRadioGroup
                    label="Cyber Liability Insurance"
                    name="cyber_liability_insurance"
                    direction="row"
                    component={RadioButtonGroup}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                  />
                </Box>
                <Box width={fifty} pad={{ horizontal: 'small' }}>
                  <StyledFormField
                    label="If yes, what is your coverage limit?"
                    name="cyber_liability_coverage"
                  />
                </Box>
              </Box>
            </Box>
            <StyledBox size={size}>Reference and Interests</StyledBox>
            <Box direction="column" wrap pad={{ vertical: '60px', horizontal: 'xlarge' }}>
              <StyledFormField
                label="How did you hear about POWER Engineers?"
                name="hear_about_power"
                component={TextArea}
              />
              <StyledFormField
                label="Name of POWER Employee Who Referred You"
                name="employee_refer"
              />
              <StyledFormField
                label="Which company industry are you interested in?"
                name="company_capability_interest"
                direction="row"
                component={Select}
                options={[
                  'Agribusiness',
                  'Campus Energy',
                  'Food & Beverage',
                  'Government',
                  'Oil & Gas, and Petrochemical',
                  'Power Delivery',
                  'Power Generation',
                ]}
              />
            </Box>
            <StyledBox size={size}>Federal Programs (FAR Part 19 &amp; 26)</StyledBox>
            <Box direction="column" wrap pad={{ vertical: '60px', horizontal: 'xlarge' }}>
              <StyledFormFieldRadioGroup
                label="Is your firm registered with the System for Award Management (SAM) or SBA's (DSBS) Dynamic Small Business Search?*"
                name="sam_or_dsbs"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />
              <p>
                <strong>Note:</strong> 8(a) and HUBZone must be certified by the SBA
              </p>

              <StyledFormFieldRadioGroup
                label="Has your firm completed Online Representation and Certifications Applications (ORCA) at SAM?*"
                name="sup_div_orca"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <StyledFormFieldRadioGroup
                label="Is your firm a Small Business?*"
                name="sup_div_small_business"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <StyledFormFieldRadioGroup
                label="Is your firm a Small Disadvantaged Business?*"
                name="sup_div_sm_disadvantage_bus"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <StyledFormFieldRadioGroup
                label="Is your firm an 8(a) Small Disadvantaged Business?*"
                name="sup_div_8a_sdb"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <StyledFormFieldRadioGroup
                label="Is your firm a Woman-owned Small Business?*"
                name="sup_div_woman_owned"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <StyledFormFieldRadioGroup
                label="Is your firm a Veteran-owned Small Business?*"
                name="sup_div_veteran_owned"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <StyledFormFieldRadioGroup
                label="Is your firm a Service-disabled Veteran-owned Small Business?*"
                name="sup_div_disabled_vet_owned"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <StyledFormFieldRadioGroup
                label="Is your firm a HUBZone Small Business?*"
                name="sup_div_hubzone"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <StyledFormFieldRadioGroup
                label="Is your firm an Alaskan Native Corporation or member of a recognized Indian Tribe?*"
                name="sup_div_tribal_owned"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />
            </Box>

            <StyledBox size={size}>Unified Certification Programs (49 CFR 26)</StyledBox>
            <Box direction="row-responsive" wrap pad={{ vertical: '60px', horizontal: 'xlarge' }}>
              <StyledFormFieldRadioGroup
                label="Is your firm certified as a socially and economically Disadvantaged Business Enterprise (DBE) through a statewide Unified Certification Program (UCP)? If so, select each UCP.*"
                name="sup_div_ucp_dbe"
                direction="row"
                component={RadioButtonGroup}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                required
              />

              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ top: '20px' }}>
                <StyledFormField
                  label="Certification"
                  name="sup_div_ucp_cert1_type"
                  direction="row"
                  component={Select}
                  options={[
                    'Minority Business Enterprise (MBE)',
                    'Woman Business Enterprise (WBE)',
                    'Veteran Business Enterprise (VBE)',
                    'Service-Disabled Veteran Business Enterprise (SDVBE)',
                    'LGBT (LGBTBE)',
                  ]}
                />
              </Box>

              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField width={fifty} label="State" name="sup_div_ucp_cert1_state" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="Certifying Agency or Organization"
                  name="sup_div_ucp_cert1_org"
                />
              </Box>
              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ bottom: '40px' }}>
                <StyledFormField label="Certification Number" name="sup_div_ucp_cert1_id" />
              </Box>

              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ top: '20px' }}>
                <StyledFormField
                  label="Certification"
                  name="sup_div_ucp_cert2_type"
                  direction="row"
                  component={Select}
                  options={[
                    'Minority Business Enterprise (MBE)',
                    'Woman Business Enterprise (WBE)',
                    'Veteran Business Enterprise (VBE)',
                    'Service-Disabled Veteran Business Enterprise (SDVBE)',
                    'LGBT (LGBTBE)',
                  ]}
                />
              </Box>

              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField width={fifty} label="State" name="sup_div_ucp_cert2_state" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="Certifying Agency or Organization"
                  name="sup_div_ucp_cert2_org"
                />
              </Box>
              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ bottom: '40px' }}>
                <StyledFormField label="Certification Number" name="sup_div_ucp_cert2_id" />
              </Box>

              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ top: '20px' }}>
                <StyledFormField
                  label="Certification"
                  name="sup_div_ucp_cert3_type"
                  direction="row"
                  component={Select}
                  options={[
                    'Minority Business Enterprise (MBE)',
                    'Woman Business Enterprise (WBE)',
                    'Veteran Business Enterprise (VBE)',
                    'Service-Disabled Veteran Business Enterprise (SDVBE)',
                    'LGBT (LGBTBE)',
                  ]}
                />
              </Box>

              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField width={fifty} label="State" name="sup_div_ucp_cert3_state" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="Certifying Agency or Organization"
                  name="sup_div_ucp_cert3_org"
                />
              </Box>
              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ bottom: '40px' }}>
                <StyledFormField label="Certification Number" name="sup_div_ucp_cert3_id" />
              </Box>
            </Box>

            <StyledBox size={size}>Additional Certifications</StyledBox>
            <Box direction="row-responsive" wrap pad={{ vertical: '60px', horizontal: 'xlarge' }}>
              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ bottom: '40px' }}>
                <StyledFormFieldRadioGroup
                  label="Does your firm hold other certifications (including municipal, regional, and third party)?*"
                  name="sup_div_addl_cert_yn"
                  direction="row"
                  component={RadioButtonGroup}
                  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                  ]}
                  required
                />
              </Box>
              <Box width="100%" pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="Certification"
                  name="sup_div_addl_cert_1_type"
                  direction="row"
                  component={Select}
                  options={[
                    'Minority Business Enterprise (MBE)',
                    'Woman Business Enterprise (WBE)',
                    'Veteran Business Enterprise (VBE)',
                    'Service-Disabled Veteran Business Enterprise (SDVBE)',
                    'LGBT (LGBTBE)',
                    'Other',
                  ]}
                />

                <StyledFormField label="If other" name="sup_div_addl_cert_1_other" />
              </Box>

              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="State/Council" name="sup_div_addl_cert1_state" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Membership Number" name="sup_div_addl_cert_1_state" />
              </Box>

              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ top: '60px' }}>
                <StyledFormField
                  label="Certification"
                  name="sup_div_addl_cert_2_type"
                  direction="row"
                  component={Select}
                  options={[
                    'Minority Business Enterprise (MBE)',
                    'Woman Business Enterprise (WBE)',
                    'Veteran Business Enterprise (VBE)',
                    'Service-Disabled Veteran Business Enterprise (SDVBE)',
                    'LGBT (LGBTBE)',
                    'Other',
                  ]}
                />

                <StyledFormField label="If other" name="sup_div_addl_cert_2_other" />
              </Box>

              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="State/Council" name="sup_div_addl_cert_2_state" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="Membership Number"
                  name="sup_div_addl_cert_1_number_cloned_"
                />
              </Box>

              <Box width="100%" pad={{ horizontal: 'small' }} margin={{ top: '60px' }}>
                <StyledFormField
                  label="Certification"
                  name="sup_div_addl_cert_3_type"
                  direction="row"
                  component={Select}
                  options={[
                    'Minority Business Enterprise (MBE)',
                    'Woman Business Enterprise (WBE)',
                    'Veteran Business Enterprise (VBE)',
                    'Service-Disabled Veteran Business Enterprise (SDVBE)',
                    'LGBT (LGBTBE)',
                    'Other',
                  ]}
                />

                <StyledFormField label="If other" name="sup_div_addl_cert_3_other" />
              </Box>

              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="State/Council" name="sup_div_addl_cert_3_state" />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Membership Number" name="sup_div_addl_cert_3_number" />
              </Box>
            </Box>

            <StyledBox size={size}>Additional Information</StyledBox>

            <Box
              direction="row-responsive"
              wrap
              margin={{ top: '60px' }}
              pad={{ vertical: '60px', horizontal: 'xlarge', top: 'small' }}
            >
              <Box width={thirtyThree} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Number of Employees *" name="sup_div_employees" required />
              </Box>
              <Box width={thirtyThree} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Average Annual Revenue" name="sup_div_avg_revenue" />
              </Box>
              <Box width={thirtyThree} pad={{ horizontal: 'small' }}>
                <StyledFormField label="Year Established*" name="sup_div_year" required />
              </Box>

              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField
                  label="Organization Classification"
                  name="sup_div_org_class"
                  direction="row"
                  component={Select}
                  options={[
                    'Individual proprietor or single-member LLC',
                    'C Corporation',
                    'S Corporation',
                    'Partnership',
                    'Trust/Estate',
                  ]}
                />
              </Box>
              <Box width={fifty} pad={{ horizontal: 'small' }}>
                <StyledFormField width={fifty} label="If other" name="sup_div_addl_other" />
              </Box>
              <Box pad={{ vertical: '50px' }}>
                <FormField
                  label="Under penalties of perjury, I certify that the information provided on this form is accurate and true to the best of my knowledge. [Pursuant to 15 U.S.C 645(d)] *"
                  name="sup_div_info_is_accurate"
                  component={CheckBox}
                  required
                />
              </Box>

              <p>
                Contact{' '}
                <a href="mailto:supplierdiversity@powereng.com">supplierdiversity@powereng.com</a>{' '}
                with questions or for any additional information.
              </p>
            </Box>

            <Box align="center">
              <Button type="submit" label="Submit" primary />
              {isError && errorData && (
                <Box width="full" pad={{ vertical: 'large', horizontal: 'xlarge' }}>
                  There was an error submitting the form:
                  <StyledError>{errorData}</StyledError>
                </Box>
              )}
            </Box>
          </StyledForm>
        </>
      )}
    </Box>
  );
}
