import React from 'react';
import { Box, Heading, Text, Grommet, ResponsiveContext } from 'grommet';
import { RichText } from 'prismic-reactjs';
import theme from '../../grommet/Theme';

function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'small', vertical: 'xlarge' };
    case 'medium':
      return 'large';
    default:
      return { top: 'xlarge', horizontal: 'xlarge', bottom: 'large' };
  }
}

function centeredText(size) {
  switch (size) {
    case 'small':
      return '100%';
    case 'medium':
      return '100%';
    default:
      return '66%';
  }
}

export default function TitleDescription({ heading, description, htmlSerializer }) {
  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {(sizeResponsive) => {
          return (
            <Box>
              <Box width="1440px" alignSelf="center" pad={mainPadding(sizeResponsive)}>
                <Heading size={sizeResponsive} margin="0px" alignSelf="center" level={2}>
                  {heading}
                </Heading>
                <Box alignSelf="center" width={centeredText(sizeResponsive)}>
                  <Text textAlign="center">
                    <RichText render={description} htmlSerializer={htmlSerializer} />
                  </Text>
                </Box>
              </Box>
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}
