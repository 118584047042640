import React from 'react';
import styled from 'styled-components';
import { Box, Image, Text, Heading } from 'grommet';
import { RichText } from 'prismic-reactjs';
import GatsbyButton from '../GatsbyButton';

const CardTitleText = styled(Heading)`
  margin: 0px;
  padding: 30px 0px 0px 0px;
`;

const CardBodyText = styled(Text)`
  color: #272727;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export default function({ Link, image, title, blurb, size, ctaText, ctaUrl, htmlSerializer }) {
  const width = size === 'small' ? '100%' : '50%';
  return (
    <Box width={width}>
      {image && (
        <Box>
          <Image src={image.url || ''} alt={image.alt || ''} width="100%" />
        </Box>
      )}
      <CardTitleText size={size} level="3">
        {title}
      </CardTitleText>
      <CardBodyText size={size}>
        <RichText render={blurb} htmlSerializer={htmlSerializer} />
      </CardBodyText>
      {ctaText ? (
        <Box>
          <ButtonContainer>
            <GatsbyButton Link={Link} primary label={ctaText} href={!ctaUrl ? '#' : ctaUrl} />
          </ButtonContainer>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
}
