export function mainPadding(size) {
  switch (size) {
    case 'small':
      return { horizontal: 'medium', vertical: 'xlarge' };
    case 'medium':
      return 'large';
    default:
      return 'xlarge';
  }
}

export function mainGap(size) {
  switch (size) {
    case 'small':
      return 'large';
    case 'medium':
      return 'large';
    default:
      return 'xlarge';
  }
}
