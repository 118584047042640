import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionPanel } from 'grommet';
import ServicesPanel from './ServicesPanel';

const StyledAccordionPanel = styled(AccordionPanel)`
  ${({ active }) => (active ? 'background: #C4EBF6;' : '')}
  position: relative;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    border-bottom: 1px solid #272727;
  }

  h4 {
    color: #272727 !important;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? '700' : 'normal')};
    line-height: 20px;
    margin: 20px 0;
    text-transform: none;
  }

  svg {
    color: #272727 !important;
  }
`;

export default function({ Link, segments, htmlSerializer }) {
  const [activeIndex, setActiveIndex] = useState([0]);

  return (
    <Accordion
      activeIndex={activeIndex}
      margin={{ bottom: 'large' }}
      onActive={(index) => setActiveIndex(index)}
      multiple
      border="top"
    >
      {segments &&
        segments.map((segment, index) => {
          const active = activeIndex.includes(index);

          return (
            <StyledAccordionPanel
              label={segment.title}
              key={`panel-${segment.id}`}
              pad={{ horizontal: 'medium' }}
              active={active}
            >
              <ServicesPanel
                Link={Link}
                htmlSerializer={htmlSerializer}
                key={segment.id}
                {...segment}
              />
            </StyledAccordionPanel>
          );
        })}
    </Accordion>
  );
}
