import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import RamSVG from './RamSvg';

const Underline = styled(Box)`
  border-bottom: 12px solid #${(props) => props.color};
`;

export default function Ram({ color }) {
  return (
    <Underline color={color} pad={{ top: 'large', horizontal: 'xlarge' }}>
      <Box width="1440px" margin={{ horizontal: 'auto', bottom: '-10px' }}>
        <RamSVG color={color} />
      </Box>
    </Underline>
  );
}
