import React from 'react';

export default function() {
  return (
    <svg width="37" height="40" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.278 32.295V.312h4.444v31.982l13.075-10.607 2.48 3.578L18.5 39.687.722 25.265l2.48-3.578 13.076 10.608z"
        fill="#272727"
        fillRule="evenodd"
      />
    </svg>
  );
}
